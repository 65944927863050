import React, { useEffect, useState } from 'react'
import "assets/css/Dashboard/Announcement.css"
// import BoldHeading from 'components/BoldHeading/BoldHeading'
import { Link } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "assets/css/Dashboard/FeaturedCompany.css";
import "assets/css/Dashboard/TitleCard.css";
import { memberAnnouncements } from "redux/Actions/Member";
import { useDispatch, useSelector } from 'react-redux'
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';

const Announcement = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const dispatch = useDispatch();
  const { announcementsList } = useSelector(state => state.member);
  useEffect(() => {
    dispatch(memberAnnouncements({}));
  }, []);
  const [featuredData, setFeaturedData] = useState([]);
  useEffect(() => {
    let tempData = [];
    if (Object.keys(announcementsList).length > 0
      && announcementsList.data.length > 0) {
      announcementsList.data.map((item, index) => {
        tempData.push({
          title: item.title,
          description: item.description,
          img: item.thumbnail,
          index: index + 1,
          id: item.id
        });
      });
    }
    setFeaturedData(tempData);
  }, [announcementsList])


  return (
    <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
      <div className="widget widget-table-one">
        <div className="widget-heading">
          <h5 className="">Announcements</h5>
        </div>
        <div className="widget-content mt-3" style={{ paddingRight: "15px" }}>
          <div className="row">
            <Carousel
              removeArrowOnDeviceType={["mobile"]}
              responsive={responsive}
            >
              {featuredData?.length > 0 &&
                featuredData?.map((item, index) => {
                  return (
                    <div className=" col-12 p-2" key={`we${index}`}>
                      <div className="card widget overflow-hidden p-0">
                        <div style={{ backgroundImage: `url(${item.img})`, backgroundSize: "cover", height: "200px" }}></div>
                        <div className="p-3" style={{ minHeight: "200px" }}>
                          <h6 className="mb-3 font-17 strong" style={{ fontWeight: "bolder" }}>{item?.title}</h6>
                          <p className="font-12 text-muted mb-3">
                            <span dangerouslySetInnerHTML={{ __html: item?.description.slice(0, 200) }} ></span></p>
                        </div>
                        <div className="col-12 card-footer">
                          <div className="d-flex col-12 justify-content-center btn btn-sm btn-primary text-white">
                            <Link to={`/announcements/details/${item.id}`}> Read More</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })

              }
            </Carousel>
            {featuredData?.length < 0 &&
              featuredData?.map((item, index) => {
                return (
                  <div className=" col-12 d-flex justify-content-center">Submit Announcements*</div>
                )
              })
            }
          </div>
        </div>
        <div className='col-12 d-flex justify-content-center mt-3'>
          <div className="btn btn-sm btn-primary text-white ">
            <Link to="/announcements"> View More</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Announcement