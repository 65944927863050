import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpotlightListing } from "redux/Actions/Spotlight";
import { Link } from 'react-router-dom';
import "assets/css/Spotlights/Spotlights.css"
import Carousel from "react-multi-carousel";
import { AiOutlineInbox } from "react-icons/ai"

const SpotlightListing = ({ spot }) => {
  const dispatch = useDispatch();
  const { spotlightListing } = useSelector(state => state.spotlight);

  useEffect(() => {
    if (typeof spot !== 'undefined' && spot === 'sub') {
      dispatch(getSpotlightListing({ history: 1 }));
    } else {
      dispatch(getSpotlightListing({}));
    }

  }, []);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <>
      {Object.keys(spotlightListing).length > 0
        && spotlightListing.data.length > 0 ?
        spotlightListing.data.map((item, index) => {
          // if (typeof spot !== 'undefined' && spot === 'sub' && item.status === 1) {
          const thumbnail = item.thumbnails.split(',');
          return (
            <div key={index} className="col-12 col-xl-4 py-3">
              <div className="card widget overflow-hidden p-0">
                <div style={{ backgroundImage: `url(${thumbnail[0]})`, backgroundSize: "cover", height: "250px" }}></div>
                {thumbnail.length > 1 &&
                  <Carousel
                    responsive={responsive}
                    autoPlay={false}
                    autoPlaySpeed={1000}
                    infinite={true}
                  >
                    {thumbnail.map((item, index) => {
                      if (index !== 0) {
                        return (
                          <div key={`s${index}`} className='multiple-image mt-2 px-2'>
                            <img src={item} width={50} height={50} />
                          </div>
                        )
                      }
                    })}
                  </Carousel>                  
                }
                <div className="p-3" style={{ minHeight: "280px" }}>
                  <h6 className="mb-3 strong" style={{ fontWeight: "600",fontSize:"17px" }}>{item.title}</h6>
                  <h6 className="mb-3" style={{ fontSize: "14px" }}>Category: {item.category_title}</h6>
                  <p className="mb-3 spotlightPara" style={{ fontSize: "14px",color:"#515050" }}>
                    <span dangerouslySetInnerHTML={{ __html: item.description.slice(0, 200) }} >
                    </span>
                  </p>
                </div>
                <div className="col-12 card-footer">
                  <div className="d-flex col-6 justify-content-center btn btn-sm btn-primary text-white">
                    <Link to={`/talaspotlight/details/${item.id}`} > Read More</Link>
                  </div>
                </div>
              </div>
            </div >
          )
          // }
        }) : <div className="list-group-item list-group-item-action d-flex flex-column justify-content-center">
          <div className='d-flex justify-content-center'>
            <AiOutlineInbox
              style={{ color: "grey", fontSize: "32px" }}
            />           
          </div>
          <div className='d-flex justify-content-center mt-2'  style={{ color: "grey"}}>
           Spotlight not submitted
          </div>
        </div>
      }
    </>
  )
}

export default SpotlightListing
