import React, { useState, useEffect } from 'react'
import "assets/css/Exhibitions/Exhibitions.css"
import { BsArrowLeftShort } from "react-icons/bs"
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTeamMembers,
  getExhibition
} from "redux/Actions/EventExhibition";
import { setLogout } from "redux/Reducers/LoggedSlice";
import { useNavigate } from "react-router-dom";
import AddEvent from './AddEvent';
import Exhibition from './Exhibition';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { FiInbox } from "react-icons/fi"
import { ToastContainer, toast } from 'react-toastify';

const ExhibitionDetails = ({
  setShowDetails,
  showDetails }) => {
  const [month, setMonth] = useState(false);
  useEffect(() => {
    const data = { year: showDetails };
    if (month) { data.month = month }
    dispatch(getExhibition(data))
  }, [month])
  const onChange = (date, dateString) => {
    if (dateString !== '') {
      setMonth(moment(dateString).format('MM'));
    } else {
      setMonth(false);
    }
  };
  const { isSuccessAttend } = useSelector(state => state.exhibition);
  useEffect(() => {
    if (isSuccessAttend) {
      toast.success('Attending Events Submitted Successfully...', {
        toastId: `success`
      })
  }
  }, [isSuccessAttend])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    tokenExp,
    teamMember,
    exhibitionDetails } = useSelector(state => state.exhibition);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (tokenExp && Object.keys(tokenExp).length > 0
      && tokenExp.error === 'Invalid token') {
      dispatch(setLogout());
      navigate("/");
    }
  }, [tokenExp]);

  useEffect(() => {
    dispatch(getTeamMembers());
  }, []);

  useEffect(() => {
    if (showDetails !== 0) {
      dispatch(getExhibition({ year: showDetails }))
    }
  }, [showDetails])


  return (
    <div className="layout-px-spacing">
      <ToastContainer />
      <div className="row layout-spacing layout-top-spacing" id="cancel-row">
        <div className="col-lg-12">
          <div className="widget-content searchable-container grid">
            <div className="card-box">
              <div className="row w-100">
                <div className="col-xl-12 col-lg-12 align-self-center d-flex flex-row w-100">
                  <div className='d-flex flex-row col-md-6 gap-2'>
                    <div onClick={() => setShowDetails(0)} className="col-md-2 btn btn-sm btn-primary p-1">
                      <div className='mt-1'>
                        <i className="las la-arrow-left">
                          <BsArrowLeftShort />
                        </i>
                        <span className='mt-2'>Back</span>
                      </div>
                    </div>
                    <div className='col- col-md-3 d-flex contact-options'>
                      <Space direction="vertical">
                        <DatePicker onChange={onChange} className='datepicker' picker="month" />
                      </Space>
                    </div>
                  </div>
                  <div className='col-md-6 d-flex justify-content-end' >
                    <button type="button"
                      className="btn btn-outline-success"
                      onClick={() => setShow(true)}>
                      Add Event
                    </button>
                  </div>
                  <AddEvent show={show} setShow={setShow} year={showDetails} />
                </div>
              </div>
            </div>
            {Object.keys(exhibitionDetails).length > 0
              && exhibitionDetails.data.length > 0
              ? exhibitionDetails.data.map((item, index) => {
                return (
                  <Exhibition
                    key={`ex${index}`}
                    item={item}
                    showDetails={showDetails}
                    teamMember={teamMember}
                  />
                )
              }) :
              <div className="card-box col-lg-12" style={{ marginTop: "25px" }}>
                <div className='d-flex flex-column justify-content-center dataNotfound'>
                  <div className='d-flex justify-content-center'>
                    <FiInbox style={{ fontSize: "30px", color: "#666666" }} />
                  </div>
                  <div className='d-flex justify-content-center' style={{ color: "#666666" }}>
                    Data not found...
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExhibitionDetails
