import React, { useEffect, useState } from 'react'
import "assets/css/QuoteForm/QuoteForm.css"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useForm } from "react-hook-form";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialState } from "redux/Reducers/SendQuoteSlice"
import { sendQuote, sendsQuotes } from "redux/Actions/SendQuote";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuoteForm = ({ placeholder, autoFill }) => {
  // console.log('autoFill',autoFill)
  const [value, setValueM] = useState(false);
  const [isBlank, setIsBlank] = useState(false);
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const [imageFile, setImageFile] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, isMessage, isSuccess } = useSelector(state => state.sendquote);
  const { memberProfileData } = useSelector(state => state.member);

  const [OMEV, setOMEV] = useState(false);

  useEffect(() => {
    let ids = '', email = '';
    if (autoFill.length > 0) {
      autoFill.map((item, index) => {
        email += `${item.email}${autoFill.length !== index + 1 ? ',' : ''}`;
        ids += `${item.id}${autoFill.length !== index + 1 ? ',' : ''}`;
      })
    }
    setOMEV(ids)
    setValue('to', email);
  }, [autoFill])

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset();
        dispatch(setInitialState(false));
        setValueM(false);
        dispatch(sendsQuotes());
        toast.success("Quote Send Successfully...")
      }, 2000);
    }
  }, [isMessage])
  const onSubmit = async (fdata) => {
    if (!value || value === '') {
      setIsBlank(true);
      return false;
    } else {
      setIsBlank(false);
    }
    let from = fdata.from;
    // let to = fdata.to;
    let cc = fdata.cc;
    let subject = fdata.subject;
    var formdata = new FormData();
    formdata.append("from", from);
    formdata.append("cc", cc);
    // formdata.append("member_id", OMEV);
    formdata.append("to", OMEV);
    formdata.append("subject", subject);
    formdata.append("message", value);
    formdata.append("attachment", imageFile);
    dispatch(sendQuote(formdata));

  }


  const handleChange = (e) => {
    setImageFile(e.target.files[0]);
  }

  return (
    <>
      <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        {isLoading &&
          <div className="spin-loader">
            {/* <InfinitySpin width='200' color="#bbce00" /> */}
            <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
          </div>
        }
        {/* {isSuccess && <span className="success d-block">
          {isMessage.msg} </span>} */}
        <div className="w-100">
          <div className="form-group row">
            <div className="col-lg-6">
              <label className='mb-2'>From:</label>
              <input type="email" className="form-control"
                placeholder="From"
                defaultValue={Object.keys(memberProfileData).length > 0
                  && Object.keys(memberProfileData.data).length > 0
                  && memberProfileData.data.email}
                {...register("from", {
                  required: "From email should not be empty",
                })} />
              {errors.from && <span className="error d-block">{errors.from.message}</span>}
            </div>
            <div className="col-lg-6">
              <label className='mb-2'>Cc:</label>
              <input type="email" className="form-control"
                placeholder='Type email Id here'
                {...register("cc", {
                  required: false,
                })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6">
              <label className='mb-2'>To:</label>

              <input type="text" className="form-control"
                placeholder="Select Licensee from Above Search Results"
                readOnly
                {...register("to", {
                  required: "To email should not be empty",
                })}
              />
              {errors.to && <span className="error d-block">{errors.to.message}</span>}
            </div>
            <div className="col-lg-6">
              <label className='mb-2'>Subject:</label>
              <input type="text" className="form-control" placeholder={'Subject'} defaultValue={placeholder}
                {...register("subject", {
                  required: "Subject should not be empty",
                })} />
              {errors.subject && <span className="error d-block">{errors.subject.message}</span>}
            </div>
            <div className="col-lg-12">
              <br /><br />
              <div className="">
                <div className="form-group row">
                  <label className='mb-2'>Message</label>
                  <div className="col-lg-12 col-sm-12">
                    <ReactQuill
                      theme="snow"
                      value={value}
                      onChange={(e) => {
                        setValueM(e)
                      }}
                      placeholder='Type Your Message'
                    />
                    {isBlank && <span className="error d-block">Message should not be empty</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <label className='mb-2'>Upload File</label>
              <div className="input-group">
                <input type="file" name='attachment' className="form-control"
                  onChange={(e) => handleChange(e)} />
              </div>
            </div>
          </div>
        </div>
        <div className="widget-footer text-right">
          <button type="submit" className="btn btn-primary mr-2">Submit</button>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default QuoteForm