import React, { useEffect } from 'react';
import "assets/css/AnnualConference/AnnualConference.css"
// import { MdFlight } from "react-icons/md"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Timer from '../Timer/Timer';
import { useDispatch, useSelector } from 'react-redux';
import { getConferenceBanner } from "redux/Actions/AnnualConference";
import { saveAs } from "file-saver";
import annual from "assets/img/annual.jpg"

const AnnualConferenceCard = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConferenceBanner());
  }, [])
  const { conferenceBanner } = useSelector(state => state.annualconference);

  const downloadFile = (url) => {
  //   URL.createObjectURL(object)
  //   fetch(url).then(response => {
  //     response.blob().then(blob => {
  //         // Creating new object of PDF file
  //         const fileURL = window.URL.createObjectURL(blob);
  //         // Setting various property values
  //         let alink = document.createElement('a');
  //         alink.href = fileURL;
  //         alink.download = url;
  //         alink.click();
  //     })
  // })
    (async () => {
      saveAs(url, "doc-file");
    })();
  }

  return (
    <div className="widget-header">
      <div className="row">
        <div className="col-lg-12 col-md-12 p-0">
          <Carousel
            // autoPlay={true}
            removeArrowOnDeviceType={["mobile"]}
            responsive={responsive}
          >

            {Object?.keys(conferenceBanner)?.length > 0
              && Object?.keys(conferenceBanner?.data)?.length > 0 &&
              <div className='anual-banner'>
                <img src={conferenceBanner?.data?.banner_url} className='img-fluid' />
                <div className="col-12 d-flex flex-column justify-content-center carousel-captions ">
                  <h3 className='carousel-titles px-5' style={{ fontSize: "45px" }}>
                    {/* <MdFlight />
                    TALA */}
                    <img src={annual} className='img-fluid'  />
                  </h3>
                  <h5>Days left for the Conference </h5>
                  <div>
                    <Timer start_on={conferenceBanner?.data?.start_on}/>
                  </div>
                  <div className='mt-5'>                    
                    <button type="button" className="btn btn-primarys mr-2"
                      onClick={() => downloadFile(conferenceBanner?.data?.document_url)}>
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
            }
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default AnnualConferenceCard