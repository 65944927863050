import React from 'react'
import "assets/css/Dashboard/Activity.css"
import BoldHeading from 'components/BoldHeading/BoldHeading'
import { useSelector } from "react-redux";
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'

const Activity = () => {
  const { notificationList } = useSelector(state => state.notfication);
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    let fa = [];
    if (Object.keys(notificationList).length > 0
      && notificationList.data.length > 0) {
      const NL = notificationList.data.length;
      const NLD = notificationList.data;
      if (NL > 2) {
        fa = [
          { key: 3, title: NLD[NL - 1].title, date: moment(NLD[NL - 1].created_at).format('MMM DD') },
          { key: 2, title: NLD[NL - 2].title, date: moment(NLD[NL - 2].created_at).format('MMM DD') },
          { key: 1, title: NLD[NL - 3].title, date: moment(NLD[NL - 3].created_at).format('MMM DD') }
        ];
      } else if (NL === 2) {
        fa = [
          { key: 3, title: NLD[NL - 1].title, date: moment(NLD[NL - 1].created_at).format('MMM DD') },
          { key: 2, title: NLD[NL - 2].title, date: moment(NLD[NL - 2].created_at).format('MMM DD') }
        ];
      } else {
        fa = [
          { key: 3, title: NLD[NL - 1].title, date: moment(NLD[NL - 1].created_at).format('MMM DD') }
        ];
      }
    }
    setActivity(fa);
  }, [notificationList]);
  // console.log('activity',activity)
  return (
    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 layout-spacing">
      <div className="widget widget-three">
        <BoldHeading
          Boldheading="Activity"
        />
        <div className="widget-content mt-4">
          <div className="">
            <ol className="activity-feed">
              {activity.length > 0
                && activity.map(item => {
                  return (
                    <li key={`sm${item?.key}`} className="feed-item">
                      <div className="feed-item-list">
                        <span className="date">{item?.date}</span>
                        <span className="activity-text">{item?.title}</span>
                      </div>
                    </li>
                  );
                })
              }
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Activity