import React, { useEffect } from 'react'
import "assets/css/MyAccount.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setIsUpdate } from "redux/Reducers/MemberSlice";
import { updateProfile, memberProfile } from "redux/Actions/Member";

const MySocialNetwork = () => {

  const { memberProfileData, isUpdate } = useSelector(state => state.member);  
  const { register, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(memberProfileData).length > 0
      && Object.keys(memberProfileData.data).length > 0) {
      setValue('twitter', `${memberProfileData.data?.twitter}`);
      setValue('linkedin', `${memberProfileData.data?.linkedin}`);
    }
  }, [memberProfileData])
  useEffect(() => {
    if (isUpdate) {
      setTimeout(() => {
        dispatch(memberProfile());
        dispatch(setIsUpdate(false));
      }, 3000);
    }
  }, [isUpdate])

  const onSubmit = async (fdata) => {
    var formdata = new FormData();
    formdata.append("linkedin", fdata.linkedin);
    formdata.append("twitter", fdata.twitter);
    dispatch(updateProfile(formdata));
  }

  return (
    <div className='col-12'>
      <div className='col-12 d-flex justify-content-center mt-3'>
        <h4>MY SOCIAL<span style={{ color: "#bbce00" }}> NETWORK</span>
        </h4>
      </div>
      <div className='col-12 d-flex justify-content-center mt-3'>
        {isUpdate &&
          <span className="success d-block">
            Member infomation update Successfully...
          </span>
        }
      </div>
      <div className="row">
        <form className="login-form mt-4"
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingRight: "30%", marginTop: "30px", marginBottom: "30px" }}>
          <div className="col-md-12 clearfix">
            <div className="single-query1">
              <label>LinkedIn:</label>
              <input type="text" placeholder="http://linkedin.com"
                className="keyword-input"
                {...register("linkedin", {
                  required: false,
                })} />
            </div>
          </div>
          <div className="col-md-12 clearfix">
            <div className="single-query1">
              <label>Twitter:</label>
              <input type="text" placeholder="http://twitter.com"
                className="keyword-input"
                {...register("twitter", {
                  required: false,
                })} />
            </div>
          </div>
          <div className='col-12 d-flex justify-content-end mb-4 mt-3'>
            <div className="col-4 btn btn-sm btn-primary text-white ">
              <button type="submit" className="btn btn-primary mr-2">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MySocialNetwork