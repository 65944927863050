import React, { useEffect, useState } from 'react'
import Cards from 'components/Cards/Cards'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "assets/css/Spotlights/Spotlights.css"
import { RotatingLines } from 'react-loader-spinner';
import { api } from "lib/api";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { getSpotlightCategoryListing } from "redux/Actions/Spotlight"
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import SpotlightListing from "components/Spotlight/SpotlightListing";
import { getSpotlightListing } from "redux/Actions/Spotlight";
import { ToastContainer, toast } from 'react-toastify';

const TalaSpotlights = () => {
  const dispatch = useDispatch();
  const { spotlightCatgoryListing } = useSelector(state => state.spotlight);

  const [value, setValue] = useState(false);
  const [isBlank, setIsBlank] = useState(false);
  const [isBlankImage, setIsBlankImage] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    dispatch(getSpotlightCategoryListing());
  }, [])

  useEffect(() => {

    if (isMessage && typeof isMessage.message === 'undefined') {
      setApiError(toast.warning("The title has already been taken."))
    } else {

      if (isSuccess) {
        setTimeout(() => {
          dispatch(getSpotlightListing({ history: 1 }));
          setFileList([])
          reset();
          setIsMessage(false);
          setIsSuccess(false);
          setValue(false);
        }, 2000);
      }
    }
  }, [isMessage])
  const onSubmit = async (fdata) => {
    if (fileList.length === 0) {
      setIsBlankImage(true);
      return false;
    } else {
      setIsBlankImage(false);
    }
    if (!value || value === '') {
      setIsBlank(true);
      return false;
    } else {
      setIsBlank(false);
    }

    // console.log('fileList', fileList);

    let comments = fdata.comments;
    let id_category = fdata.id_category;
    // let post = fdata.post;
    let title = fdata.title;
    try {
      setIsLoading(true);
      var formdata = new FormData();
      formdata.append("title", title);
      formdata.append("post", '');
      formdata.append("id_category", id_category);
      formdata.append("comments", comments);
      formdata.append("description", value);
      fileList.forEach((file) => {
        formdata.append('thumbnails[]', file.originFileObj);
      });

      // formdata.append("thumbnail", imageFile);

      const { data, status } = await api.post(
        "spotlight/add",
        formdata,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (status === 200) {
        setIsMessage(data);
        setIsSuccess(true);
        setIsLoading(false);
        toast.success("Spotlight Submitted successfully")
      }
    } catch (error) {
      console.log(error);
      toast.error("Spotlight Submitted Failed")
    } finally {
      setIsLoading(false);
    }
  }


  const props = {
    multiple: true,
    onChange(file) {
      setFileList([...file.fileList]);
    },
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      // setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const para = <>Share about your company's most recent updates you are proud of,
    accomplishments, and significant milestones by providing a concise description of
    these events including high-resolution images. <br />
    Given below are some of the announcements that can be shared on TALA social
    network platforms.
    <br />
    <ul>
      <li>Unique cargo handling</li>
      <li>Milestones achieved.</li>
      <li>Attending/Participating in MRO events</li>
      <li>Management updates/ Leadership team updates</li>
      <li>New Ventures</li>
    </ul>

    Your valuable news will be featured on TALA dashboard, LinkedIn page, email
    newsletter and other social media platforms to create brand among other TALA
    Network and global audience.
  </>

  return (
    <div className="layout-px-spacing">
      <div className="row layout-spacing pt-4">
        <div className="col-lg-12 layout-spacing">
          <Cards
            heading="Spotlight Submission"
            Para={para}
          />
        </div>

        <form className="login-form" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          {isLoading &&
            <div className="spin-loader-submission">
              {/* <InfinitySpin width='200' color="#bbce00" /> */}
              <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
            </div>
          }
          <div className='col-xl-12 col-lg-4 col-md-4 col-sm-12'>
            <div className='widget-content widget-content-area'>
              <div className='widget-header'>
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4 className='annualTitles'>Spotlight Submission</h4><br />
                  </div>
                  {isSuccess &&
                    <div className="success d-block">
                      {isMessage.message}  </div>
                  }
                </div>
              </div>
              <div className="w-100">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className='mb-2'>Title</label>
                    <input type="text" className="form-control"
                      placeholder='Title'
                      {...register("title", {
                        required: "Title should not be empty",
                        onChange: (e) => { setApiError(false); },
                      })} />
                    {apiError && <span className="error d-block">{apiError}</span>}
                    {errors.title && <span className="error d-block">{errors.title.message}</span>}
                  </div>
                  <div className="col-lg-6">
                    <label className='mb-2'>Category</label>
                    <select {...register("id_category", {
                      required: "Category should not be empty",
                    })} className="form-control">
                      <option value="">Select Category</option>
                      {Object.keys(spotlightCatgoryListing).length > 0
                        && spotlightCatgoryListing.data.length > 0 &&
                        spotlightCatgoryListing.data.map((item, key) => {
                          return (<option value={item.id} key={key}>{item.title}</option>)
                        })
                      }
                    </select>
                    {errors.id_category && <span className="error d-block">{errors.id_category.message}</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label className='mb-2'>Upload a File</label>
                    <br />
                    <Upload {...props}
                      defaultFileList={[...fileList]}
                      listType="picture"
                      className="upload-list-inline"
                    >
                      <Button icon={<UploadOutlined />}>Upload here</Button>
                    </Upload>
                    {isBlankImage && <span className="error d-block">Inage should not be empty</span>}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <div className="form-group row">
                      <label className='mb-2'>Description</label>
                      <div className="col-lg-12 col-sm-12">
                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={(e) => {
                            setValue(e)
                          }}
                          placeholder='Type Your Description'
                        />
                        {isBlank && <span className="error d-block">Description should not be empty</span>}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label className='mb-2'>Additional Comments to Team</label>
                    <div className="input-group">
                      <textarea className="form-control" style={{ height: "4.75rem" }}
                        {...register("comments", {
                          required: false,
                        })}>
                      </textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget-footer text-right">
                <button type="submit" className="btn btn-primary mr-2">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
      {/* <div className="row">
        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
          <br /> <h4 style={{ textAlign: "center" }}>Submission History</h4><br />
        </div>
        <SpotlightListing spot={"sub"} />
      </div> */}
      <div className='col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing'>
        <div className='widgetNoPad widget-table-one'>
        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
          <h4 style={{ textAlign: "center",color:"white",backgroundColor:"#1b4d70",padding:"20px"}}>Submission History</h4><br />
        </div>
          <div className='widget-content' style={{padding:"20px"}}>
            <div className="row">
              <SpotlightListing spot={"sub"} />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default TalaSpotlights
