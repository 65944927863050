import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialState } from "redux/Reducers/AnnualConferenceSlice"
import { interestToSponser } from "redux/Actions/AnnualConference"
import { setLogout } from "redux/Reducers/LoggedSlice";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Sponser = ({ list }) => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading,
    tokenExp,
    isSuccess } = useSelector(state => state.annualconference);
  const { companyProfileData } = useSelector(state => state.company);
  const { memberProfileData } = useSelector(state => state.member);
  const { conferenceBanner } = useSelector(state => state.annualconference);
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  useEffect(() => {
    setValue('name',`${memberProfileData?.data?.first_name} ${memberProfileData?.data?.last_name}`)
    setValue('designation',memberProfileData?.data?.designation)
  }, [])

  useEffect(() => {
    if(list?.sponser){
      setValue('sponsers', list?.sponser);
    }
  }, [list?.sponser])
  useEffect(() => {
    if (tokenExp && Object.keys(tokenExp).length > 0
      && tokenExp.error === 'Invalid token') {
      dispatch(setLogout());
      navigate("/");
    }
  }, [tokenExp])
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset();
        dispatch(setInitialState(false));
      }, 2000);
    }
  }, [isSuccess])
  const onSubmit = async (data) => {  
    data.conference_id = conferenceBanner?.data?.id;
    dispatch(interestToSponser(data));
    toast.success("Interest to Sponsor Submitted Successfully... ")

  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        {isLoading &&
          <div className="spin-loader">
            {/* <InfinitySpin width='200' color="#bbce00" /> */}
            <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
          </div>
        }
        {/* {isSuccess &&
          <span className="success d-block">
            {isSuccess.msg}
          </span>
        } */}
        <div className="form-group row pt-5">
          <div className="col-lg-6">
            <label className='mb-2'>Name</label>
            <input type="text" className="form-control"
              {...register("name", {
                required: "Name should not be empty",
              })} />
            {errors.name && <span className="error d-block">{errors.name.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Designation</label>
            <input type="text" className="form-control"
              {...register("designation", {
                required: "Designation should not be empty",
              })} />
            {errors.designation && <span className="error d-block">{errors.designation.message}</span>}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-6">
            <label className='mb-2'>Email Address</label>
            <input type="email" className="form-control"
              {...register("email", {
                required: "Email should not be empty",
              })} />
            {errors.email && <span className="error d-block">{errors.email.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Phone Number</label>
            <input type="number" className="form-control"
              {...register("phone", {
                required: "Phone number should not be empty",
              })} />
            {errors.phone && <span className="error d-block">{errors.phone.message}</span>}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-6">
            <label className='mb-2'>Company Name</label>
            <input type="text" className="form-control"
              placeholder='XYZ Company'
              {...register("company_name", {
                required: "Company Name should not be empty",
              })}
              value={companyProfileData?.data?.company_info?.company_name}
              readOnly />
            {errors.company_name && <span className="error d-block">{errors.company_name.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Select Sponsers</label>
            <select className="form-control"
              {...register("sponsers", {
                required: "Sponsers should not be empty",
              })}
              >
              <option value={""}>-Select-</option>
              {Object.keys(list?.sponsersList).length > 0 &&
                list.sponsersList.data.length > 0 &&
                list.sponsersList.data.map((item, index) => {
                  return (
                    <option key={`sp${index}`} value={item.category}>{item.category}</option>
                  )
                })}
            </select>
            {errors.sponsers && <span className="error d-block">{errors.sponsers.message}</span>}
          </div>
        </div>

        <div className="form-group row">
          <div className="widget-footer">
            <br /><br />
            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default Sponser