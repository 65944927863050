import React, { useState, useEffect } from 'react'
import SVQuoteCard from './SVQuoteCard/SVQuoteCard'
import SVQuoteForm from './SVQuoteForm/SVQuoteForm'
import SVQuoteTable from './SVQuoteTable/SVQuoteTable'
import SearchBox from 'components/Search/SearchBox'
import ReceiveQT from './SVQuoteTable/ReceiveQT'
import { useDispatch, useSelector } from 'react-redux';
import { receivedQuote, sendsQuotes } from "redux/Actions/SendQuote"

const SendViewQuote = () => {
  const [autoFill, setAutoFill] = useState([]);
  const { quoteList, sendQuoteList} = useSelector(state => state.sendquote);
  const dispatch = useDispatch();
    
    useEffect(() => {
      dispatch(receivedQuote());
      dispatch(sendsQuotes());
    }, [])
  return (
    <div className="layout-px-spacing">
      <div className="row layout-spacing pt-4">
        <SearchBox heading="Send View Licensee Search" type="quote"/>
        <SVQuoteCard setAutoFill={setAutoFill} />
        <SVQuoteForm autoFill={autoFill} />
        <SVQuoteTable quoteList={sendQuoteList}/>
        <ReceiveQT quoteList={quoteList}/>
      </div>
    </div>
  )
}

export default SendViewQuote