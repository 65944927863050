import React from 'react'
import "assets/css/Spotlights/Spotlights.css"
import TalaSpotlightsCard from './TalaSpotlightsCard/TalaSpotlightsCard';

const TalaSpotlights = () => {
  
  return (
    <div className="layout-px-spacing">
      <TalaSpotlightsCard />
    </div>
  )
}

export default TalaSpotlights