import React, { useEffect } from 'react';
// import { Carousel } from 'antd';
import Carousel from "react-multi-carousel";
import "assets/css/Spotlights/Spotlights.css";
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useState } from 'react';
import { BsArrowLeftShort } from "react-icons/bs";

const SpotlightDetails = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const { spotlightListing } = useSelector(state => state.spotlight);
  let { id } = useParams();
  const [currentSpotlight, setCurrentSpotlight] = useState(false);
  useEffect(() => {
    if (Object.keys(spotlightListing).length > 0
      && spotlightListing.data.length > 0) {
      spotlightListing.data.map(item => {
        if (item.id === parseInt(id)) {
          setCurrentSpotlight(item);
        }
      })
    }
  }, [id])


  return (
    <>
      <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        {currentSpotlight &&
          <div className="widget widget-table-one">
            <div className="col-xl-12 col-lg-12 align-self-center w-100">
              <Link to="/talaspotlight">
                <div className="btn btn-sm btn-primary p-1">
                  <i className="las la-arrow-left">
                    <BsArrowLeftShort />
                  </i>
                  Back
                </div>
              </Link>
            </div>
            <div className="col-xl-12">
              <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <br /> <h4 style={{ textAlign: "center" }}>{currentSpotlight?.title}</h4><br />
              </div>
              <div className="overflow-hidden p-0">
                {
                  (() => {
                    const thumbnail = currentSpotlight?.thumbnails.split(',');
                    return (
                      // <Carousel
                      //   autoPlay={true}
                      //   removeArrowOnDeviceType={["mobile"]}
                      //   responsive={responsive}
                      //   autoPlaySpeed={2000}
                      //   infinite={true}
                      // >
                      <>
                        {thumbnail?.length > 0 &&
                          thumbnail?.map((item, index) => {
                            return (<img key={`i${index}`} src={item} width="100%" height="300px" />);
                          })
                        }
                        </>
                      // </Carousel>
                    )
                  })()
                }
              </div>
            </div>
            <div className="widget-content" style={{ padding: "15px" }}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="overflow-hidden p-0">
                    <div className="p-4">
                      <h6 className="mb-3 font-17 strong" style={{ textAlign: "center",fontWeight:"bold" }}>{currentSpotlight?.title}</h6>
                      <h6 className="mb-4 font-17" style={{ textAlign: "center" }}>{currentSpotlight?.category_title}</h6>
                      <p className="" style={{ fontSize: "14px",color:"#515050" }}>
                        <span dangerouslySetInnerHTML={{ __html: currentSpotlight?.description }} ></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default SpotlightDetails

