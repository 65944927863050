import React from 'react'
import { Popover, Avatar, List } from 'antd';
import { CiMenuKebab } from "react-icons/ci"
import { MdDelete } from "react-icons/md"
import { FaBellSlash, FaVoteYea } from "react-icons/fa"


const AllNotifications = ({data,handleOnClick}) => {

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item className={`${item.is_read === 1 ? 'read' : 'unread'}`}>
            <List.Item.Meta
              avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
              title={item.title}
              description={item?.description}
            />
            <div className={`d-flex flex-column`}>
              <div>{item.hours}</div>
              <Popover content={
                <>
                  <div className="mb-3" style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                    onClick={() => handleOnClick('make_delete', item?.id)}>
                    <MdDelete /> Delete Notification
                  </div>
                  <div className="mb-3" style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                    onClick={() => handleOnClick('is_mute', item?.id)}>
                    <FaBellSlash /> Mute Notification
                  </div>
                  <div style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                    onClick={() => handleOnClick('is_read', item?.id)}>
                    <FaVoteYea /> Mark Read
                  </div>
                </>
              } title="" trigger="hover" placement="bottomRight">
                <div className=' col-12 d-flex justify-content-end'><CiMenuKebab /></div>
              </Popover>
            </div>
          </List.Item>
        )}
      />
    </div>
  )
}

export default AllNotifications