import React, { useEffect, useState } from 'react';
import BoldHeading from 'components/BoldHeading/BoldHeading';
import { useDispatch, useSelector } from 'react-redux';
import { companyList } from "redux/Actions/Company";
import Search from "components/Search/Search";
import { BiRefresh } from "react-icons/bi";
import "assets/css/Search.css";
import {
  loctaionsCountryList,
  loctaionsRegionList
} from "redux/Actions/Member";
import {
  setCountryList,
  setForSearchInit
} from 'redux/Reducers/MemberSlice';
import { useNavigate } from "react-router-dom";
import { setExteraRoute } from "redux/Reducers/LoggedSlice";


const SearchBox = ({ heading, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyListData } = useSelector(state => state.company);
  const {
    forSearch,
    regionList,
    countryList,
  } = useSelector(state => state.member);

  useEffect(() => {
    if (type === 'dashboard' && Object.keys(forSearch).length > 0) {
      dispatch(setExteraRoute('Licensee Search'));
      navigate('/membersearch');
    }
  }, [forSearch])

  useEffect(() => {
    dispatch(companyList());
    dispatch(loctaionsRegionList({}));
    dispatch(setCountryList());
  }, []);

  const [value, setValue] = useState({});
  const [searchLicensees, setSearchLicensees] = useState([]);
  const [searchContinent, setSearchContinent] = useState([]);
  const [searchCountry, setSearchCountry] = useState([]);

  useEffect(() => {
    let company = [];
    let region = [];
    if (Object.keys(companyListData).length > 0
      && companyListData.data.length) {
      companyListData.data.map(item => {
        company.push({
          value: item.company_name,
          label: item.company_name
        })
      })
    }

    if (Object.keys(regionList).length > 0
      && regionList.data.length) {
      regionList.data.map(item => {
        region.push({
          value: item,
          label: item
        })
      })
    }
    setSearchLicensees(company);
    setSearchContinent(region);
  }, [])

  useEffect(() => {
    let country = [];
    if (Object.keys(countryList).length > 0
      && countryList.data.length) {
      countryList.data.map(item => {
        country.push({
          value: item,
          label: item
        })
      })
    }

    setSearchCountry(country);
  }, [countryList]);


  useEffect(() => {
    if (Object.keys(value).length > 0 && value.name === 'region') {
      dispatch(loctaionsCountryList({ region: value.value }));
    }
  }, [value])

  const ResetSearch = () => {
    // console.log('reset');
    dispatch(setForSearchInit());
  }

  return (
    <div className="">
      <div className="widget-header">
        <div className="row">
          <div className="memberHeader col-xl-12 col-md-12 col-sm-12 col-12">
            <div className="widget-header">
              <BoldHeading
                Boldheading={heading}
              />
            </div>
            <br />
          </div>
        </div>
      </div>
      <div className="card-box">
        <div className="col-12 col-md-12 d-flex flex-md-row flex-column justify-content-between gap-2">
          <div className='col-12 col-md-4 d-flex justify-content-start filtered-list-search'>
            <Search
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              name="company_name"
              placeholder="Search Licensees"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={searchLicensees}
              className="searchAntd"
            />
          </div>
          <div className='col-12 col-md-3 d-flex contact-options'>
            <Search
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              name="region"
              placeholder="Search Continent"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={searchContinent}
              setValue={setValue}
            />
          </div>
          <div className='col-12  col-md-3 d-flex contact-options '>
            <Search
              allowClear
              showSearch
              style={{
                width: "100%",
              }}
              name="country"
              placeholder="Search Country"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
              options={searchCountry}
              notFoundContent="Select Continent"
            />
          </div>
          <div className="col-md-2 btn btn-sm btn-primaryss">
            <div className='mt-1' onClick={() => ResetSearch()}>
              <i className="las la-arrow-left">
                <BiRefresh style={{ fontSize: "20px" }} />
              </i>
              <span className='mt-2'>Reset</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBox