import React from 'react'
import ExhibitionCard from '../Exhibitions/ExhibitionCard/ExhibitionCard'

const Exhibitions = () => {
  return (
    // <div className="layout-px-spacing">
    //   <div className="row layout-spacing pt-4">
        <ExhibitionCard />
    //   </div>
    // </div>
  )
}

export default Exhibitions