import React, { useState, useEffect } from 'react'
import "assets/css/InstantQuote/InstantQuote.css"
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialState } from "redux/Reducers/MemberSlice";

const columns = [
  {
    title: 'Licensee Name',
    dataIndex: 'membername',
  },
  {
    title: 'Company Name',
    dataIndex: 'companyname',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'Airport Code',
    dataIndex: 'airportcode',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

const SVQuoteCard = ({ setAutoFill }) => {
  const dispatch = useDispatch();
  const { memberSearchResult } = useSelector(state => state.member);
  const [companyData, setCompnayData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  useEffect(() => {
    dispatch(setInitialState());
  }, [])
  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    onChange: onSelectChange,
  }
  useEffect(() => {
    let AF = [];
    if (typeof rowSelection.selectedRowKeys !== 'undefined' &&
      rowSelection.selectedRowKeys.length > 0) {
      rowSelection.selectedRowKeys.map(item => {
        AF.push({
          email:memberSearchResult.data[item].email,
          id:memberSearchResult.data[item].id
        })
      })    
    }
    setAutoFill(AF)
  }, [selectedRowKeys])


  useEffect(() => {
    let member = []
    if (Object.keys(memberSearchResult).length > 0
      && memberSearchResult.data.length > 0) {
      memberSearchResult.data.map((item, index) => {
        member.push({
          key: index,
          membername: `${item.first_name} ${item.last_name}`,
          companyname: item.company_name,
          country: item.country,
          city: item.city,
          airportcode: item.company_airport_code,
          status: item.status === item.status === 0 ? 'Expired' : 'Active',
        })
      })
    }
    setCompnayData(member);
  }, [memberSearchResult])

  let locale = {
    emptyText: (
      <span style={{fontSize:"14px"}}>
       Search Licensees
      </span>
    )
  };
  

  return (
    <div className="col-lg-12 layout-spacing" style={{ marginTop: "25px" }}>
      <div className="statbox widget box">
        <div className="widget-header">
          <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
              <h4 style={{
                fontSize: "20px",
                color: "#3b3f5c",
                fontWeight: "600",
                padding: "12px"
              }}>
                Search Result
              </h4>
            </div>
          </div>
        </div>
        <div>
          <Table
          locale={locale}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={companyData}
            pagination={{
              pageSize: 5,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SVQuoteCard