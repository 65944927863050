import React from "react";
import "./App.css"
import "./index.css"
import Router from "routes/Router";
function App() {
  return (
    <Router />
  );
}

export default App;
