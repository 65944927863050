import React, { useEffect } from 'react';
import "assets/css/Spotlights/Spotlights.css";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort } from "react-icons/bs"

const AnnouncementDetails = () => {
  const { announcementsList } = useSelector(state => state.member);
  let { id, d } = useParams();  
  const [currentAnnouncements, setCurrentAnnouncements] = useState(false);
  useEffect(() => {
    if (Object.keys(announcementsList).length > 0
      && announcementsList.data.length > 0) {
      announcementsList.data.map(item => {
        if (item.id === parseInt(id)) {
          setCurrentAnnouncements(item);
        }
      })
    }
  }, [id])
  return (
    <>
      <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
        {currentAnnouncements &&
          <div className="widget widget-table-one">
            <div className="col-xl-12 col-lg-12 align-self-center w-100">
              <Link to={`${typeof d !== "undefined" ? '/dashboard': '/announcements'}`}>
                <div className="btn btn-sm btn-primary p-1">
                  <i className="las la-arrow-left">
                    <BsArrowLeftShort />
                  </i>
                  Back
                </div>
              </Link>
            </div>
            <div className="col-xl-12">
              <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <br /> <h4 style={{ textAlign: "center" }}>{currentAnnouncements?.title}</h4><br />
              </div>
              <div className="overflow-hidden p-0">
                <img src={currentAnnouncements?.thumbnail} width="100%" style={{height:"350px"}}/>
              </div>
            </div>
            <div className="widget-content" style={{ padding: "15px" }}>
              <div className="row">
                <div className="col-xl-12">
                  <div className="overflow-hidden p-0">
                    <div className="p-4">
                      <h6 className="mb-3 font-17 strong">{currentAnnouncements?.title}</h6>
                      <h6 className="mb-3 font-17">{currentAnnouncements?.category_title}</h6>
                      <p className="font-12 text-muted mb-3" style={{ color: "#000" }}>
                        <span dangerouslySetInnerHTML={{ __html: currentAnnouncements?.description }} ></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default AnnouncementDetails

