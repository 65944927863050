import React, { useState, useEffect } from 'react'
import "assets/css/Dashboard/Dashboard.css"
import { Popover, Avatar, List, Divider } from 'antd';
import { CiMenuKebab } from "react-icons/ci"
import { MdDelete } from "react-icons/md"
import { FaBellSlash, FaRegBell, FaVoteYea } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications, actionPerform } from "redux/Actions/Notifications";
import { setInitialState } from "redux/Reducers/NotificationsSlice";
import Modal from 'react-bootstrap/Modal';
import AllNotifications from './AllNotifications/AllNotifications';
import { RotatingLines } from 'react-loader-spinner';
const NotificationContent = () => {
  const [lgShow, setLgShow] = useState(false);
  const handleSelect = () => {
    setLgShow(true)
  }
  const dispatch = useDispatch();
  const current = new Date().getTime();
  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getNotifications());
  //   }, 15000);
  // }, [])
  const { isLoadingA, isSuccess, notificationList } = useSelector(state => state.notfication);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setInitialState(false));
        dispatch(getNotifications());
      }, 1000);
    }
  }, [isSuccess])
  const [data, setData] = useState([]);
  const [cdata, setCData] = useState([]);
  useEffect(() => {
    let n = [];
    let c = [];
    if (Object.keys(notificationList).length > 0
      && notificationList.data.length > 0) {
      notificationList.data?.map((item, index) => {
        var past_now = new Date(item?.created_at).getTime();
        var delta = Math.abs(current - past_now) / 1000;
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        var seconds = Math.floor(delta % 60);
        if (index < 5) {
          c.push(
            {
              index: index + 1,
              id: item?.id,
              is_read: item?.is_read,
              title: item?.title,
              description: item?.description,
              hours: days !== 0 ? `${days} d`
                : (hours !== 0 ? `${hours} h`
                  : (minutes !== 0 ? `${minutes} m`
                    : `${seconds} s`))
            }
          )
        }
        n.push(
          {
            index: index + 1,
            id: item?.id,
            is_read: item?.is_read,
            title: item?.title,
            description: item?.description,
            hours: days !== 0 ? `${days} d`
              : (hours !== 0 ? `${hours} h`
                : (minutes !== 0 ? `${minutes} m`
                  : `${seconds} s`))
          }
        )
      })
    }
    setCData(c)
    setData(n);
  }, [notificationList])

  const handleOnClick = (type, id) => {
    const data = {
      notification_id: id,
      [type]: 1
    };
    dispatch(actionPerform(data));
  }


  return (
    <div>
      {isLoadingA &&
        <div className="col-xl-12 col-lg-12">
          <RotatingLines
            height="20"
            width="20"
            color="#bbce00"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>}
      <ul className="col-6 d-flex justify-content-end flex-row profile-option-container">
        <li className="option-item dropdown notification-dropdown">
          <Popover content={<>
            <List
              itemLayout="horizontal"
              dataSource={cdata}
              renderItem={(item, index) => (
                <List.Item className={`${item.is_read === 1 ? 'read' : 'unread'}`}>

                  <List.Item.Meta
                    avatar={<Avatar src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`} />}
                    title={item?.title}
                    description={item?.description}
                  />
                  <div className={`d-flex flex-column`}>
                    <div>{item.hours}</div>
                    <Popover content={
                      <>
                        <div className="mb-3" style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                          onClick={() => handleOnClick('make_delete', item?.id)}>
                          <MdDelete /> Delete Notification
                        </div>
                        <div className="mb-3" style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                          onClick={() => handleOnClick('is_mute', item?.id)}>
                          <FaBellSlash /> Mute Notification
                        </div>
                        {item.is_read === 0 &&
                          <div style={{ fontFamily: 'Kurale', cursor: "pointer" }}
                            onClick={() => handleOnClick('is_read', item?.id)}>
                            <FaVoteYea /> Mark Read
                          </div>
                        }
                      </>
                    } title="" trigger="hover" placement="bottomRight">
                      <div className=' col-12 d-flex justify-content-end'><CiMenuKebab /></div>
                    </Popover>
                  </div>
                </List.Item>
              )}
            />
            <Divider style={{ color: "black" }} />
            <div className='col-12 d-flex justify-content-center '>
              <span className="btn btn-sm bg-gradient-primary text-white mb-1"
                onClick={handleSelect}>View More</span>
            </div>
            <Modal
              size="lg"
              show={lgShow}
              onHide={() => setLgShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
              scrollable={true}
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  View All Notifications
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <AllNotifications
                  handleOnClick={handleOnClick}
                  data={data}
                />
              </Modal.Body>
            </Modal></>} title="Notifications">

            <div className="option-link-container">
              <div className="option-link">
                <i className="las la-bell">
                  <FaRegBell />
                </i>
                {
                  (() => {
                    const found = data.filter(item => item.is_read === 0)
                    if (found.length > 0) {
                      return (
                        <div className="blink">
                          <div className="circle"></div>
                        </div>
                      )
                    }
                  })()
                }
              </div>
              {
                (() => {
                  const found = data.filter(item => item.is_read === 0)
                  if (found.length > 0) {
                    return (
                      <div className="text-left">
                        <p style={{ marginTop: "12px", fontSize: "12px" }}>{found.length} Unread</p>
                      </div>
                    )
                  }
                })()
              }
            </div>
          </Popover>
        </li>
      </ul>
    </div >
  )
}

export default NotificationContent