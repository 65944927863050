import React, { useRef, useEffect } from "react";
import flight from "assets/img/flight.png"
import talalogo from "assets/img/talaLogo.jpg"
import "assets/css/Login.css"
import { useForm } from "react-hook-form";
import { updatePassword } from "redux/Actions/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { setInitialState } from "redux/Reducers/AuthenticationSlice";
import { RotatingLines } from 'react-loader-spinner';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();
  const { isLoading, isSuccess, error, isMessage } = useSelector(state => state.authentication);
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const { isLoggedin } = useSelector(state => state.loggedin);

  useEffect(() => {
    if (isSuccess || isLoggedin) {
      navigate(`/dashboard`);
    }
  }, [isSuccess])

  const [UPS, setUPS] = useState(false);
  useEffect(() => {
    if (isMessage) {
      dispatch(setInitialState());
      setUPS(true);
      setTimeout(() => {
        navigate(`/`);
      },2000)
    }
  }, [isMessage])

  const onSubmit = (data) => {
    data.token = token;
    dispatch(updatePassword(data));
    toast.success("Reset Password Successfully!!!");
  }

  return (
    <div className='slider-area banner-style-5 bg-images'>
      <div className='wrapper d-flex'>
        <div className="container">
          <img src={talalogo} />
          <div className="row g-5">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="inner pt--100 pt_md--0 pt_sm--0">
                <div className="contents text-start">
                  <div className='card logincard'>
                    <div className="login-form-box checkout-page-style">
                      {isLoading &&
                        <div className="spin-loader">
                          {/* <InfinitySpin width='200' color="#bbce00" /> */}
                          <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
                        </div>
                      }
                      <h3 className="mb-30">Reset Password</h3>
                      {error && <span className="error d-block">
                        Something wents wrong Please try again. </span>
                      }
                      {UPS && <span className="success d-block">
                        Password update Successfully </span>}
                      <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-box mb-4">
                          <input
                            type="password"
                            placeholder="Password"
                            className="col-12"
                            name="password"
                            {...register("password", {
                              required: "Password should not be empty",
                            })}
                          />
                          {errors.password && <span className="error d-block">{errors.password.message}</span>}
                        </div>

                        <div className="input-box mb-4">
                          <input
                            type="password"
                            placeholder="Password"
                            className='col-12'
                            {...register("password_confirmation", {
                              required: "Confirm password should not be empty",
                              validate: value => value === password.current || "The passwords do not match."
                            })}
                          />
                          {errors.password_confirmation && <span className="error d-block">{errors.password_confirmation.message}</span>}
                        </div>
                        <button type="submit" className="btn btn-primary btn-lg"> Update </button>
                        <div className="input-box forget col-12 d-flex justify-content-center">
                          <Link to="/" className="lost-password" >Login</Link>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="banner-image">
                <div className="thumbnail">
                  <img src={flight} alt="Banner Images" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
