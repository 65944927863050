import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialState } from "redux/Reducers/AnnualConferenceSlice"
import { interestToAttend } from "redux/Actions/AnnualConference"
import { setLogout } from "redux/Reducers/LoggedSlice";
import { useNavigate } from "react-router-dom";
import { companyList } from "redux/Actions/Company";
import { ToastContainer, toast } from 'react-toastify';
import { getConferenceBanner } from "redux/Actions/AnnualConference";

const Attend = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(companyList());
  }, []);
  const { isLoading,
    tokenExp,
    isSuccess } = useSelector(state => state.annualconference);
  const {
    companyProfileData,
    // companyListData 
  } = useSelector(state => state.company);
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [colleague, setColleague] = useState("");
  const { memberProfileData } = useSelector(state => state.member);
  const { conferenceBanner } = useSelector(state => state.annualconference);
  useEffect(() => {
    setValue('name',`${memberProfileData?.data?.first_name} ${memberProfileData?.data?.last_name}`)
    setValue('designation',memberProfileData?.data?.designation)
  }, [])

  useEffect(() => {
    if (tokenExp && Object.keys(tokenExp).length > 0
      && tokenExp.error === 'Invalid token') {
      dispatch(setLogout());
      navigate("/");
    }
  }, [tokenExp])
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset();
        dispatch(getConferenceBanner());
        dispatch(setInitialState(false));
        setColleague('');
      }, 2000);
    }
  }, [isSuccess])
  const onSubmit = async (data) => {   
    data.conference_id = conferenceBanner?.data?.id;
    dispatch(interestToAttend(data));
    toast.success("Interest to Attend Submitted Successfully... ")
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} >
        {isLoading &&
          <div className="spin-loader">
            {/* <InfinitySpin width='200' color="#bbce00" /> */}
            <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
          </div>
        }
        {/* {isSuccess &&
          <span className="success d-block">
            {isSuccess.msg}
          </span>
        } */}
        <div className="form-group row pt-5">
          <div className="col-lg-6">
            <label className='mb-2'>Name</label>
            <input type="text" className="form-control"
              {...register("name", {
                required: "Name should not be empty",
              })} />
            {errors.name && <span className="error d-block">{errors.name.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Designation</label>
            <input type="text" className="form-control"
              {...register("designation", {
                required: "Designation should not be empty",
              })} />
            {errors.designation && <span className="error d-block">{errors.designation.message}</span>}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-6">
            <label className='mb-2'>Email Address</label>
            <input type="email" className="form-control"
              {...register("email", {
                required: "Email should not be empty",
              })} />
            {errors.email && <span className="error d-block">{errors.email.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Phone Number</label>
            <input type="number" className="form-control"
              {...register("phone", {
                required: "Phone number should not be empty",
              })} />
            {errors.phone && <span className="error d-block">{errors.phone.message}</span>}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-lg-6">
            <label className='mb-2'>Company Name</label>
            <input type="text" className="form-control"
              placeholder='XYZ Company'
              {...register("company_name", {
                required: "Company Name should not be empty",
              })}
              value={companyProfileData?.data?.company_info?.company_name}
              readOnly />
            {errors.company_name && <span className="error d-block">{errors.company_name.message}</span>}
          </div>
          <div className="col-lg-6">
            <label className='mb-2'>Add Colleague</label>
            <select className="form-control"
              {...register("colleague", {
                required: "Colleague should not be empty",
              })}
              value={colleague}
              onChange={(e) => setColleague(e.target.value)}>
              <option value={""}>-Select-</option>
              <option value={"Jack"}>Jack</option>
              <option value={"Jacob"}>Jacob</option>
              <option value={"Rosey"}>Rosey</option>
              <option value={"Others"}>Others</option>
            </select>
            {errors.colleague && <span className="error d-block">{errors.colleague.message}</span>}
          </div>
        </div>
        {colleague === "Others" &&
          <>
            <h5 className='mt-4'>Add New Colleague Details</h5>
            <div className="form-group row pt-2">
              <div className="col-lg-6">
                <label className='mb-2'>Name</label>
                <input type="text" className="form-control"
                  {...register("others.name", {
                    required: "Name should not be empty",
                  })} />
                {errors.others?.name && <span className="error d-block">{errors.others?.name?.message}</span>}
              </div>
              <div className="col-lg-6">
                <label className='mb-2'>Designation</label>
                <input type="text" className="form-control"
                  {...register("others.designation", {
                    required: "Designation should not be empty",
                  })} />
                {errors.others?.designation &&
                  <span className="error d-block">{errors.others?.designation.message}</span>}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-6">
                <label className='mb-2'>Email Address</label>
                <input type="email" className="form-control"
                  {...register("others.email", {
                    required: "Email should not be empty",
                  })} />
                {errors.others?.email &&
                  <span className="error d-block">{errors.others?.email?.message}</span>}
              </div>
              <div className="col-lg-6">
                <label className='mb-2'>Phone Number</label>
                <input type="number" className="form-control"
                  {...register("others.phone", {
                    required: "Phone number should not be empty",
                  })} />
                {errors.others?.phone &&
                  <span className="error d-block">{errors.others?.phone?.message}</span>}
              </div>
            </div>
            {/* <div className="form-group row">
              <div className="col-lg-6">
                <label className='mb-2'>Company Name</label>
                <select className="form-control"
                  {...register("others.company_id", {
                    required: "Company should not be empty",
                  })}
                >
                  <option value={""}>-Select-</option>
                  {Object.keys(companyListData).length > 0
                    && companyListData.data.length
                    && companyListData.data.map((item, index) => {
                      return (
                        <option key={`cm${index}`} value={item?.id}>{item?.company_name}</option>
                      )
                    })}
                </select>
                {errors.others?.company_id &&
                  <span className="error d-block">{errors.others?.company_id?.message}</span>}
              </div>
            </div> */}
          </>
        }
        <div className="form-group row">
          <div className="widget-footer">
            <br /><br />
            <button type="submit" className="btn btn-primary mr-2">
              Submit
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default Attend