import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  // InboxOutlined, 
  LogoutOutlined
} from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "assets/css/Dashboard/Content.css"
import { setExteraRoute } from "redux/Reducers/LoggedSlice";
import { setLogout } from "redux/Reducers/LoggedSlice";
import { useDispatch, useSelector } from "react-redux";
import { companyProfile } from "redux/Actions/Company";
import profile1 from "assets/img/profile.jpg"
import "assets/css/Dashboard/Dashboard.css"
import NotificationContent from "./NotificationContent/NotificationContent";
import MyAccount from "./MyAccount/MyAccount";

const Header = ({ sidebarroutes }) => {
  const dispatch = useDispatch();
  const { memberProfileData } = useSelector(state => state.member);
  const { exteraRoute } = useSelector(state => state.loggedin);
  const [fname, setFname] = useState(false);
  const [lname, setLname] = useState(false);
  const [profile, setProfile] = useState('');
  const [show, setShow] = useState(false);
  // console.log('profile',profile)
// console.log('memberProfileData',memberProfileData);

  useEffect(() => {
    if (Object.keys(memberProfileData).length > 0
      && Object.keys(memberProfileData.data).length > 0) {
      dispatch(companyProfile(memberProfileData.data.company_id));
      setFname(memberProfileData.data.first_name)
      setLname(memberProfileData.data.last_name)
      setProfile(memberProfileData.data.profile_logo_url)
    }
  }, [memberProfileData])

  const handleDropdownItemClick = (e) => {
    switch (e.key) {
      case "logout":
        dispatch(setLogout());
        break;
    }
  }



  const items = [
    {
      label: `Welcome ${fname} ${lname} !`,
      key: 'wellcome_admin',
    },
    {
      type: 'divider',
    },
    {
      label: <span className="account">My Account</span>,
      key: 'my_account',
      icon: <UserOutlined />,
      onClick: () => setShow(true)
    },
    {
      type: 'divider',
    },
    {
      label: <span className="account">LogOut</span>,
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: handleDropdownItemClick
    },
  ];

  return (
    <div className='row header-container fixed-top '>
      <div className="col-3"></div>
      <header className='col-9 d-md-flex d-none  header navbar navbar-expand-sm'>
        <ul className="col-12 navbar-item flex-row">
          <li className="col-4 navbar navbar-expand-lg navbar-light px-1">
          </li>
          <li className="col-8 nav-item dropdown user-profile-dropdown d-md-flex d-none justify-content-end fixed-profile1">
            <NotificationContent />
            <div className="col-2 nav-link user" id="userProfileDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <Dropdown
                menu={{
                  onClick: items.onClick,
                  items: items,
                }}
                trigger={['click']}
              >
                <div onClick={(e) => e.preventDefault()}>
                  <Space>
                    <img src={profile !== '' ? profile : profile1} alt="avatar" className="cursor-pointer" />
                  </Space>
                </div>
              </Dropdown>
            </div>
          </li>
          <>
            <MyAccount
              show={show}
              // fullscreen={fullscreen}
              setShow={setShow}
            />
          </>
        </ul>
      </header>
      <div className="d-md-none d-flex">
        {['sm'].map((expand, index) => (
          <Navbar key={`h${index}`} bg="light" expand={expand} className="mb-3 col-12 ">
            <Container fluid>
              <div className='col col-md-5 mt-4 px-2'>
                <Navbar.Brand href="/">
                  <h4 className='brandName'>
                    TALA
                  </h4></Navbar.Brand>
              </div>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <div className="fixed-profile d-flex justify-content-center gap-3">
                      <div className="premium-border ">
                        <img src={profile} className="profile-avatar" />
                      </div>
                      <div className="mt-3">
                        <h6 className="font-14 mb-1">Sara Smith</h6>
                        <p className="font-13 mb-0">Britannia</p>
                      </div>
                    </div>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='mt-5'>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <ul className="list-unstyled menu-categories ps ps--active-y " id="accordionExample">
                      {sidebarroutes.map((data, index) => {
                        return (
                          <li key={`h${index}`} className={`menu ${data.name === exteraRoute ? 'clicked' : ''}`} onClick={() => dispatch(setExteraRoute(data.name))} >
                            <div data-active={`${data.name === exteraRoute ? true : false}`} className="main-item">
                              <div className='navlinkTitle'>
                                <Nav.Link className='d-flex flex-row gap-2 justify-content-start  px-2 mobileNavLink'>
                                  <div className='menuIcons'>{data.img}</div>
                                  <h4 className='sublinking'>{data.name}</h4>
                                </Nav.Link>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};

export default Header;
