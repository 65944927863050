import React, { useState } from 'react'
import "assets/css/InstantQuote/InstantQuote.css"
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Chats from '../Chats/Chats';
import { useDispatch } from "react-redux";
import { setInitialMessage } from "redux/Reducers/ResolutionCenterSlice";
import { Input, Select } from 'antd';
// import { AiFillFileText } from "react-icons/ai";
import { CaretDownOutlined } from '@ant-design/icons'

const ResolutionTable = ({ data, setSC }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [caseId, setCaseId] = useState(false);
  const [id, setID] = useState(false);

  const handleClose = () => {
    dispatch(setInitialMessage());
    setShow(false);
    setCaseId(false);
    setID(false);
  }
  const handleOnClick = (CTID, CASEID) => {
    setID(CTID);
    setCaseId(CASEID);
    setShow(true);
  }
  const options = [
    {
      value: 'Open',
      label: 'Open',
    },
    {
      value: 'Resolved',
      label: 'Resolved',
    }
  ]

  return (
    <div className="col-lg-12 layout-spacing">
      <div className="statbox widget box">
        <div className="widget-header">
          <div className="col-12 d-flex flex-row mb-4">
            <div className="col-md-6 col-12">
              <h4 style={{ fontSize: "20px", color: "#3b3f5c", fontWeight: "600", padding: "12px" }}>History</h4>
            </div>
            <div className='col-12 col-md-6 d-flex justify-content-end  contact-options'>
              <div className='col-12 col-md-6 d-flex justify-content-end '>
                <Select
                  // suffixIcon={<CaretDownOutlined style={{ marginBottom: "15px", color: "#1B4D70" }}
                  // onClick={() => setOpen(!open)}/>}
                  // open={open}                 
                  allowClear
                  showSearch
                  style={{ width: "100%",marginTop:"12px" }}
                  placeholder={'Search Status'}
                  optionFilterProp={'children'}
                  options={options}
                  onChange={(value) => {
                    setSC(value);
                    // setOpen(!open)
                  }}
                  // onClick={() => setOpen(!open)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th><div className="th-content">Case Id</div></th>
                  <th><div className="th-content">Company Name</div></th>
                  <th><div className="th-content">Licensee Name</div></th>
                  <th><div className="th-content">Created Date</div></th>
                  {/* <th><div className="th-content">Due Date</div></th> */}
                  <th><div className="th-content">Status</div></th>
                  {/* <th><div className="th-content">File</div></th> */}
                  <th><div className="th-content">View Conversation</div></th>
                </tr>
              </thead>
              <tbody>
                {typeof data !== 'undefined'
                  && Object.keys(data)?.length > 0
                  && Object.keys(data?.data)?.length > 0
                  && data?.data?.map((item, index) => {
                    return (
                      <tr key={`res${index}`}>
                        <td>{item?.invoice_number}</td>
                        <td>{item?.company_name}</td>
                        <td>{item?.member_name}</td>
                        <td>{moment(item?.created_at).format('DD-MM-YYYY')}</td>
                        <td>
                          <span className={`badge badge-${item?.status === 0 ? 'info' : 'danger'}`}>
                            {item?.status === 0 ? 'Open' : 'Resolved'}
                          </span>
                        </td>
                        <td>
                          <button type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => handleOnClick(item?.id, item?.invoice_number)}>
                            Click Here
                          </button>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
              <Modal show={show} onHide={handleClose} dialogClassName="modal-100w">
                {/* <Modal.Body> */}
                <Chats
                  caseId={caseId}
                  id={id}
                  setShow={setShow}
                />
                {/* </Modal.Body> */}
              </Modal>
            </table>
          </div>
        </div>
      </div>
    </div>


  )
}

export default ResolutionTable