import React, { useEffect, useState } from 'react'
import "assets/css/CompanyProfile/CompanyProfile.css"
import noImage from "assets/img/no-image.jpg";
import { BsCheck2 } from "react-icons/bs"
import { FaFacebook, FaTwitter } from "react-icons/fa"
import { CiLinkedin } from "react-icons/ci"
import BoldHeading from 'components/BoldHeading/BoldHeading'
import { Link } from 'react-scroll'
import { useSelector } from 'react-redux';
import { Button, Tooltip, Select, Space } from 'antd';
import Form from 'react-bootstrap/Form';
import { BsInfoCircle } from "react-icons/bs"


const GeneralInformation = ({ data, cid, branches }) => {

  const { memberProfileData } = useSelector(state => state.member);
  const [branchId, setBranchId] = useState(false);
  const [address, setAddress] = useState(false);
  const content = (
    <div>
      <div>{data.address1}</div>
      <div>{data.address2}</div>
    </div>
  );
  useEffect(() => {
    if (branchId) {
      const address = branches.find(item => item.id === parseInt(branchId));
      setAddress(address);
    } else {
      setAddress(false);
    }
  }, [branchId])

  // console.log('branches',branches)
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-4 mb-4">
        <div className="profile-left">
          <div className="image-area mt-4">
            <img className="user-image" src={data.company_logo != '' ? data.company_logo : noImage} />
          </div>
          <div className="info-area">
            <h5>{data.company_name}</h5>
          </div>

          <div className="button-list text-center">
            <br />
            {cid === undefined ?
              <div>
                <Button disabled className='sendQuoteBtn'>
                  Send Quotes
                  <span className="btn-label-right">
                    <i className="las la-check">
                      <BsCheck2 />
                    </i>
                  </span>
                </Button>
              </div>
              :
              <Link to="ctaForQuote" smooth={true} duration={100}>
                <span type="button" className="btn btn-success">
                  Send Quotes
                  <span className="btn-label-right">
                    <i className="las la-check">
                      <BsCheck2 />
                    </i>
                  </span>
                </span>
              </Link>
            }
          </div>
          <br />
        </div>
      </div>
      <div className="col-xl-8 col-lg-8 col-md-8">
        <div className="profile-info">
          <div className='d-flex flex-row'>
            <div className="col-10 widget-header">
              <BoldHeading
                Boldheading="General Information"
              />
            </div>
            <div className='col-2 d-flex justify-content-end'>
              <div className='d-flex flex-row'>
                <div className={`status ${data.status === 'active' ? "green" : "red"}`}></div>
                <div>
                  <h6 style={{ textTransform: "capitalize", color: "#000" }}>
                    {data.status}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row ">
            <div className="col-6 single-profile-info">
              <h6>Address</h6>
              <p> {data.headquarters}</p>
            </div>
            <div className="col-6 single-profile-info">
              <h6>Website</h6>
              <p><a href={data.website} target="_blank" style={{ color: "#444" }}>{data.website} </a></p>
            </div>
          </div>
          <div className="d-flex flex-row ">
            <div className="col-6 single-profile-info">
              <h6>Enrollment</h6>
              <p>{data.enrollment_date}</p>
            </div>
            <div className="col-6 single-profile-info">
              <h6>Expires</h6>
              <p>{data.expiry_date}</p>
            </div>
          </div>
          <div className="d-flex flex-row ">
            <div className="col-6 single-profile-info cityaddress">
              <Tooltip placement="right" title={content}>                
                  <h6 className='col-3'>HQ<span style={{color:"blue",marginLeft:"10px",fontSize:"15px"}} ><BsInfoCircle/></span> </h6>                  
              </Tooltip>
              <p>{data.headquarters}</p>
            </div>
            <div className="col-6 single-profile-info">
              <h6>Branch</h6>
              <Form.Select aria-label="Default select example" size="sm"
                onChange={(e) => setBranchId(e.target.value)}>
                <option value="">Select Branch</option>
                {branches.length > 0 && branches.map((item, index) => {
                  return (<option value={item.id} key={`op${index}`}>{item.branch_name}</option>);
                })}
              </Form.Select>
            </div>
          </div>
          <div className="d-flex flex-row ">
            <div className="col-6 single-profile-info social">
              <a href={memberProfileData?.data?.linkedin} target="_blank">
                <i className="lab la-twitter text-info">
                  <CiLinkedin className='socialmedia' />
                </i>
              </a>
              <a href={memberProfileData?.data?.twitter} target="_blank">
                <i className="lab la-linkedin text-primary px-2">
                  <FaTwitter className='socialmedia' />
                </i>
              </a>
            </div>
            {branches.length > 0 &&
              <div className="col-6 single-profile-info">
                <h6>Address</h6>
                {/* <p >{address?.address1} {address?.address2} ? Select Branch</p> */}
                {address ? <p>{address.address1} {address?.address2}</p> : <p>Select Branch</p>}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralInformation