import React, { useState } from 'react'
import "assets/css/VideoGuide/Videos.css"
import { FaPhoneAlt } from "react-icons/fa"
import { GrMail } from "react-icons/gr"
import noImage from "assets/img/no-image.jpg";
import {
  BsFillArrowUpSquareFill,
  BsFillArrowDownSquareFill
} from "react-icons/bs";

const TeamMembers = ({ data }) => {
  const [up, setUp] = useState(false);
  const [down, setDown] = useState(true);
  const ScollDown = () => {
    const scrollableDiv = document.querySelector('.scrollbarsvideo');
    scrollableDiv.scrollTop = scrollableDiv.scrollTop + 250;
    if (scrollableDiv.scrollTop > 0) {
      setUp(true);
    }
    if (scrollableDiv.scrollTop >= scrollableDiv.scrollHeight - 740) {
      setDown(false);
    }
  }
  const ScollUp = () => {
    const scrollableDiv = document.querySelector('.scrollbarsvideo');
    scrollableDiv.scrollTop = scrollableDiv.scrollTop - 250;

    if (scrollableDiv.scrollTop === 0) {
      setUp(false);
    }
    if (scrollableDiv.scrollHeight > 1400) {
      setDown(true);
    }
  }

  return (
    <div className="col-xl-3 col-lg-8 col-md-8 mt-md-0">
      {data.length > 0 ?
        <>
          {up &&
            <div className='col-12 arrowUp mb-2  d-flex justify-content-center'
              onClick={() => ScollUp()}>
              <BsFillArrowDownSquareFill />
            </div>
          }
          <div className="scrollbarsvideo mt-md-0 p-1">
            <div className="">
              {data?.map((item, index) => {
                return (
                  <div key={index} className="profile-left" style={{ marginBottom: "25px" }}>
                    <center>
                      <h4>Team Licensee</h4>
                    </center>
                    <center>
                      <img src={item?.profile_logo === '' ? noImage : item?.profile_logo} alt="image"
                        className="img-fluid rounded-circle img-thumbnail teamprofile" />
                    </center>
                    <center>
                      <p className="mb-0 personName">{item?.first_name} {item?.last_name}<br />{item?.designation}</p>
                      <center>
                        <div className="info-area">
                          <h6 style={{ lineBreak: 'anywhere' }}>
                            <i className="fa fa-envelope-o" aria-hidden="true">
                              <GrMail />
                            </i>
                            &nbsp;&nbsp;{item?.email}
                          </h6>
                          <h6>
                            <i className="fa fa-phone" aria-hidden="true">
                              <FaPhoneAlt />
                            </i>
                            &nbsp;&nbsp;{item?.phone}
                          </h6>
                        </div>
                        <br />
                      </center>
                    </center>
                  </div>
                )
              })}
            </div>
          </div>
          {down &&
            <div className='col-12 arrowdown d-flex justify-content-center mt-4'
              onClick={() => ScollDown()}>
              <BsFillArrowUpSquareFill />
            </div>
          }
        </>
        : <p> No Team Member Found</p>
      }
    </div>
  )
}

export default TeamMembers