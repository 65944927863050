import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "assets/css/ModalPage.css"

const Details = ({ title, viewData }) => {
  // console.log('viewData', viewData);
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className=''>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='quate-details'>
          <div className='col-12 d-flex flex-row gap-3'>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>From :</div>
              <div className='textContent'>
                {viewData?.from}
              </div>
            </div>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>Subject :</div>
              <div className='textContent'>
                {viewData?.subject}
              </div>
            </div>
          </div>
          <div className='col-12 d-flex flex-row gap-3'>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>CC :</div>
              <div className='textContent'>
                {viewData?.cc}
              </div>
            </div>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>PostedBy :</div>
              <div className='textContent'>
                {viewData?.posted_by}
              </div>
            </div>
          </div>
          <div className='col-12 d-flex flex-row gap-3'>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>QuoteNumber :</div>
              <div className='textContent'>
                {viewData?.quote_number}
              </div>
            </div>
            <div className='col-6 d-flex flex-row'>
              <div className='textLabel'>ToCompany :</div>
              <div className='textContent'>
                {viewData?.to_company}
              </div>
            </div>
          </div>
          <div className='d-flex flex-row'>
            <div className='textLabel'>Status :</div>
            <div className='textContent'>
              {viewData?.status === 1 ? 'active' : 'Not active'}
            </div>
          </div>
          <div className='d-flex flex-row msgbox'>
            <div className='textLabel' style={{paddingTop:"10px"}}>Message :</div>
            <div className='textContent' style={{paddingTop:"10px"}}>
              <span dangerouslySetInnerHTML={{ __html: viewData.message }} ></span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  )
}

export default Details
