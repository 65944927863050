import React, { useState, useEffect } from 'react'
import "assets/css/Exhibitions/Exhibitions.css"
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getExhibitionList } from "redux/Actions/EventExhibition";
import { setLogout } from "redux/Reducers/LoggedSlice";
import { useNavigate } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import { getTeamMembers } from "redux/Actions/EventExhibition";
import Exhibition from "../ExhibitionDetails/Exhibition"
import { CaretDownOutlined } from '@ant-design/icons'
import { Select } from 'antd';
import { companyList } from "redux/Actions/Company";
import { loctaionsCountryList } from "redux/Actions/Member";

const columns = [
  {
    title: 'Name',
    dataIndex: 'member_name',
    key: 'member_name'
  },
  Table.EXPAND_COLUMN,
  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name'
  },
  {
    title: 'Event Country',
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: 'Event Name',
    dataIndex: 'exhibition_name',
    key: 'exhibition_name',
  },
  {
    title: 'Event Month',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Booth No',
    dataIndex: 'booth_number',
    key: 'booth_number'
  },
  {
    title: 'Connect',
    dataIndex: 'connecticon',
    key: 'connect'
  },
];

const ExhibitionTable = () => {
  // const [SC, setSC] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const { exhibitionDetails, teamMember } = useSelector(state => state.exhibition);
  const [eventHead, setEventHead] = useState(false);
  const [year, setYear] = useState(false);
  const { isSuccessAttend, isCancelAttend } = useSelector(state => state.exhibition);
  useEffect(() => {
    if (typeof id !== 'undefined') {
      const data = exhibitionDetails.data.find((item) => item.id == id);
      dispatch(getTeamMembers());
      const ye = data?.year.split('/');
      setEventHead(data);
      setYear(ye[2])
      dispatch(getExhibitionList({ event_id: id }))
    } else {
      dispatch(getExhibitionList({}));
    }

  }, [id, isSuccessAttend, isCancelAttend])

  // useEffect(() => {
  //   dispatch(getExhibitionList({}));
  // }, [])
  const { tokenExp, exhibitionList } = useSelector(state => state.exhibition);
  useEffect(() => {
    if (tokenExp && Object.keys(tokenExp).length > 0
      && tokenExp.error === 'Invalid token') {
      dispatch(setLogout());
      navigate("/");
    }
  }, [tokenExp]);
  const [exhibition, setExhibition] = useState([]);
  const [rowSeleted, setRowSelected] = useState([]);
  // console.log('rowSeleted', rowSeleted);
  useEffect(() => {
    if (rowSeleted.length > 0
      && typeof rowSeleted[0] !== 'undefined'
      && typeof rowSeleted[1] !== 'undefined') {
      if (Object.keys(exhibitionList).length > 0
        && exhibitionList.data.length > 0) {
        // const clickedMember = exhibitionList.data[rowSeleted];
        // const clickedMember = exhibitionList.data.find(item => item.id === rowSeleted);
        // console.log('clickedMember', clickedMember);
        // navigate(`/companyprofile/${clickedMember.company_id}/${clickedMember.member_id}`)
        navigate(`/companyprofile/${rowSeleted[0]}/${rowSeleted[1]}`)
      }
    }
  }, [rowSeleted])

  useEffect(() => {
    let tex = [];
    if (Object.keys(exhibitionList).length > 0
      && Object.keys(exhibitionList.data).length > 0) {
      exhibitionList.data.map((item, index) => {
        tex.push({
          key: index,
          connecticon: <FaPaperPlane style={{cursor:"pointer"}}/>,
          ...item,
        })
      });
    }
    setExhibition(tex);
  }, [exhibitionList]);

  const { companyListData } = useSelector(state => state.company);
  const [company, setCompany] = useState([]);
  useEffect(() => {
    let company = [];
    if (Object.keys(companyListData).length > 0
      && companyListData.data.length) {
      companyListData.data.map(item => {
        company.push({
          value: item.company_name,
          label: item.company_name
        })
      })
    }
    setCompany(company);
  }, [companyListData])
  const { countryList } = useSelector(state => state.member);
  const [country, setCountry] = useState([]);
  useEffect(() => {
    let country = [];
    if (Object.keys(countryList).length > 0
      && countryList.data.length) {
      countryList.data.map(item => {
        country.push({
          value: item,
          label: item
        })
      })
    }

    setCountry(country);
  }, [countryList]);
  useEffect(() => {
    dispatch(companyList());
    dispatch(loctaionsCountryList());
  }, []);

  const [SCD, setSCD] = useState({});
  const handleOnChange = (value, name) => {
    setSCD({ [name]: value })
    let finaldata = {};
    if (typeof id !== 'undefined') {
      finaldata.event_id = id;
    }
    if (typeof value !== 'undefined') {
      finaldata[name] = value;
    }

    dispatch(getExhibitionList(finaldata));
  }

  // console.log('exhibition',exhibition);
  return (
    <>
      {typeof id !== 'undefined' &&
        <>
          <Exhibition
            key={`ex54`}
            item={eventHead}
            showDetails={year}
            teamMember={teamMember}
          />

        </>
      }
      <div className="layout-pxx-spacing col-xl-12 col-lg-4 col-md-4 col-sm-12 mb-4">
        <div className="statbox widget box box-shadow">
          <div className="widget-header">
            <div className="col-12 d-flex flex-row mb-5 ">
              <div className="col-12 col-md-4">
                <h4 className='annualTitles'>Exhibition</h4><br />
              </div>
              <div className='col-12 d-flex flex-row col-md-8 justify-content-end gap-3'>
                <div className='col-12 col-md-5 d-flex justify-content-end  contact-options'>
                  <Select                  
                    value={SCD?.company}
                    allowClear
                    showSearch
                    style={{ width: "100%",marginTop:"10px" }}
                    placeholder={'Select Company Name'}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    optionFilterProp={'children'}
                    options={company}
                    onChange={(value) => {
                      handleOnChange(value, 'company');
                    }}                                      
                  />
                </div>
                <div className='col-12 col-md-5 d-flex justify-content-end  contact-options'>
                  <Select                    
                    value={SCD?.country}
                    allowClear
                    showSearch
                    style={{ width: "100%",marginTop:"10px" }}
                    placeholder={'Select Country'}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    optionFilterProp={'children'}
                    options={country}
                    onChange={(value) => {
                      handleOnChange(value, 'country');
                    }}                                     
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='w-100'>
            <Table
              rowSelection={{
                type: 'radio',
                // onChange: (e) => setRowSelected(e[0])
              }}
              columns={columns}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    setRowSelected([record.company_id, record.member_id]);
                  }
                };
              }}
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    style={{
                      margin: 0,
                    }}
                  >
                    Arrival Date - {record.arrival}<br />
                    Departure Date - {record.departure}
                  </div>
                ),
              }}
              dataSource={exhibition}
            />
          </div>
        </div>
      </div>

    </>
  )
}

export default ExhibitionTable
