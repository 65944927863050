import React, { useEffect, useRef } from 'react'
import "assets/css/MyAccount.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setIsUpdate } from "redux/Reducers/MemberSlice";
import { updatePassword } from "redux/Actions/Member";

const ChangePassword = () => {
  const { isUpdate, isError } = useSelector(state => state.member);
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const dispatch = useDispatch();
  const password = useRef({});
  password.current = watch("password", "");
  useEffect(() => {
    if (isUpdate) {
      setTimeout(() => {
        dispatch(setIsUpdate(false));
      }, 3000);
    }
  }, [isUpdate])

  const onSubmit = async (fdata) => {
    const data = {
      old_password: fdata.old_password,
      new_password: fdata.password
    };
    dispatch(updatePassword(data));
  }

  return (
    <div className='col-12'>
      <div className='col-12 d-flex justify-content-center mt-3'>
        <h4>CHANGE YOUR<span style={{ color: "#bbce00" }}> PASSWORD</span>
        </h4>
      </div>
      <div className='col-12 d-flex justify-content-center mt-3'>
        {isUpdate &&
          <span className="success d-block">
            Password update Successfully...
          </span>
        }
      </div>
      <div className="row">
        <form className="login-form mt-4"
          onSubmit={handleSubmit(onSubmit)}
          style={{ paddingRight: "30%", marginTop: "30px", marginBottom: "30px" }}>
          <div className="col-md-12 clearfix">
            <div className="single-query1">
              <label>Current Password</label>
              <input type="text" placeholder="Type here..."
                className="keyword-input"
                {...register("old_password", {
                  required: 'Current password should not be empty',
                })} />
              {Object.keys(isError).length > 0
                && Object.keys(isError.error).length > 0
                && typeof isError.error?.old_password !== "undefined"
                && <span className="error d-block">{isError.error?.old_password[0]}</span>
              }
              {errors.old_password &&
                <span className="error d-block">
                  {errors.old_password.message}
                </span>
              }
            </div>
          </div>
          <div className="col-md-12 clearfix">
            <div className="single-query1">
              <label>New Password</label>
              <input type="text" placeholder="Type here..." className="keyword-input"
                {...register("password", {
                  required: "Password should not be empty",
                  minLength: {
                    value: 6,
                    message: "Password must be of minimum 6 characters",
                  },
                  maxLength: {
                    value: 20,
                    message: "Password must be of maximum 20 characters",
                  }
                })} />
              {errors.password && <span className="error d-block">{errors.password.message}</span>}
            </div>
          </div>
          <div className="col-md-12 clearfix">
            <div className="single-query1">
              <label>Confirm Password</label>
              <input type="text" placeholder="Type here..." className="keyword-input"
                {...register("cpassword", {
                  required: "Confirm password should not be empty",
                  validate: value => value === password.current || "The passwords do not match."
                })} />
              {errors.cpassword && <span className="error d-block">{errors.cpassword.message}</span>}
            </div>
          </div>
          <div className='col-12 d-flex justify-content-end mb-4 mt-3'>
            <div className="col-4 btn btn-sm btn-primary text-white ">
              <button type="submit" className="btn btn-primary mr-2">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword