import React from 'react'
import "assets/css/Announcements/Announcements.css"
import Cards from 'components/Cards/Cards'

const Announce_card = () => {
    
    const para = `In this section, you will explore our licensee agreements, management news, new
    licensees, and internal announcements to foster exciting collaborations. Also, know
    our new licensee's announcement. Check this page to stay updated with TALA's
    latest internal news.`;
    return (
        <Cards
            heading="Announcements"
            Para={para}
        />
    )
}

export default Announce_card