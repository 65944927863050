import React, { useEffect, useState } from "react";
import "assets/css/Dashboard/Dashboard.css"
import { useDispatch, useSelector } from "react-redux";
import { setExteraRoute } from "redux/Reducers/LoggedSlice";
import { Link } from "react-router-dom";
import { setInitialState } from "redux/Reducers/NotificationsSlice";
import {
  actionPerform,
  getNotificationsLeftMenu
} from "redux/Actions/Notifications";
import { setForSearch } from 'redux/Reducers/MemberSlice';
import { Badge, Card, Space } from 'antd';

const Sidebar = ({ sidebarroutes }) => {
  const dispatch = useDispatch();
  const { exteraRoute } = useSelector(state => state.loggedin);
  const { isSuccess } = useSelector(state => state.notfication);

  useEffect(() => {
    setTimeout(() => {
      dispatch(getNotificationsLeftMenu());
    }, 1000);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setInitialState(false));
        dispatch(getNotificationsLeftMenu());
      }, 1000);
    }
  }, [isSuccess]);
  const { notificationLeft } = useSelector(state => state.notfication);
  // console.log('notificationLeft', notificationLeft)
  const [blink, setBlink] = useState({});
  useEffect(() => {
    let F = {};
    if (Object.keys(notificationLeft).length > 0
      && notificationLeft.data.length > 0) {
      notificationLeft.data.map(item => {
        F[item.type] = item.id;
      })
    }
    setBlink(F);
  }, [notificationLeft])
  return (
    <div className="contents">
      <div className="scrollbar">
        <ul className="list-unstyled menu-categories ps ps--active-y" id="accordionExample">
          {sidebarroutes.map((data, index) => {
            // console.log("data", data.name === "Financial Protection");
            return (
              <li key={`f${index}`} className={`menu ${data.name === exteraRoute ? 'clicked' : ''}`}
                onClick={() => {
                  dispatch(setForSearch({}));
                  dispatch(setExteraRoute(data.name));
                  if (typeof blink[data.link] !== 'undefined') {
                    dispatch(actionPerform({ type: data.link, left_menu: 1 }));
                  }
                  // dispatch(getNotificationsLeftMenu());
                }}>
                <Link to={`/${data.link}`} data-active={`${data.name === exteraRoute ? true : false}`}>
                  {typeof blink[data.link] !== 'undefined' &&
                    <div className="blinks">
                      <div className="circles"></div>
                    </div>
                  }
                  {data.name === "Financial Protection" ?
                    <>
                      <Badge.Ribbon text="Coming Soon..." color="green">
                        <div className="card finacard">
                          <i className="las la-home ribbon-corner ribbon-fold " data-active={`${data.name === exteraRoute && true}`} >
                            <div className='sidebar-icons'>{data.img}</div>
                          </i>
                          <span>{data.name}</span>
                        </div>
                      </Badge.Ribbon>
                      {/* <div>
                        <div >
                          <i className="las la-home ribbon-corner ribbon-fold" data-active={`${data.name === exteraRoute ? true : false}`} >
                            <div className='sidebar-icons'>{data.img}</div>
                          </i>
                          <span>{data.name}</span>
                        </div>
                      </div> */}
                      {/* <div className="finan" data-active={`${data.name === exteraRoute ? true : false}`}>
                        <div className="mt-2 coming" >Coming Soon...</div>
                         </div> */}
                    </>
                    :
                    <>
                      <i className="las la-home" data-active={`${data.name === exteraRoute ? true : false}`}>
                        <div className='sidebar-icons'>{data.img}</div>
                      </i>
                      <span>{data.name}</span>
                    </>
                  }
                  {/* <i className="las la-home" data-active={`${data.name === exteraRoute ? true : false}`}>
                    <div className='sidebar-icons'>{data.img}</div>
                  </i>
                  <span>{data.name}</span> */}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div >
  );
};

export default Sidebar;
