import React from 'react'
import "assets/css/Dashboard/AnnualConference.css"
import newbadge from "assets/img/newbadge.png"
import { Link } from "react-router-dom";

const AnnualConference = () => {

  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-4">
      <div className="widget overflow-hidden text-center  mb-4">
        <div className="confetti">
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
          <div className="confetti-piece"></div>
        </div>
        <img src={newbadge} height="120px" />
        <h5 className="mt-4 mb-3">8th TALA Annual Conference</h5>
        <p className="text-muted mb-2">22 - 25 September 2023, Phuket Thailand</p>
        <Link to="/annualconference/readmore">
          <button className="btn btn-sm bg-gradient-primary text-white">Check Now</button>
        </Link>
      </div>

      <div className="widget overflow-hidden">
        <p className="font-12 text-success-teal strong">Monthly Reports</p>
        <h4 className="font-17 strong mt-2 mb-2" style={{ fontSize: "18px" }}>Upload your report in Xslx format.</h4>
        <Link to='/monthlyreport/submit'>
          <span className="btn btn-sm bg-gradient-primary text-white mt-2">Submit</span><br />
        </Link>
      </div>
    </div>
  )
}

export default AnnualConference