import React, { useEffect } from 'react'
import GeneralInformation from "./GeneralInfomation/GeneralInformation"
import TeamMembers from "../VideoGuide/TeamMembers/TeamMembers"
import InsideCompanyProfile from "./InsideCompanyProfile/InsideCompanyProfile"
import SendQuote from './SendQuote/SendQuote'
import EventParticipate from './Event/EventParticipate'
import CompanySpotlight from './CompanySpotlight/CompanySpotlight'
import { useDispatch, useSelector } from 'react-redux';
import { RotatingLines } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import {
  companyProfilePage,
  memberVisitProfile
} from "redux/Actions/Company";
import {
  getExhibition
} from "redux/Actions/EventExhibition";

const CompanyProfile = () => {
  const dispatch = useDispatch();
  const { isLoading, companyData } = useSelector(state => state.company);
  const { memberProfileData } = useSelector(state => state.member);
  const { exhibitionDetails } = useSelector(state => state.exhibition);
  // console.log('exhibitionDetails',exhibitionDetails);
  let { cid, mid } = useParams();
  useEffect(() => {
    // console.log('companyData',companyData);
    if (typeof cid !== 'undefined') {
      dispatch(companyProfilePage({company_id:cid, member_id:mid}));
      dispatch(getExhibition({ going_to_attend: 1, member_id: mid }))
      dispatch(memberVisitProfile({ to_member_id: mid }));
    } else {
      dispatch(getExhibition({ going_to_attend: 1 }))
      dispatch(companyProfilePage({company_id:memberProfileData?.data?.company_id}));
    }

    if (!isLoading) {
      window.scrollTo(0, 0);
    }
  }, [cid, mid])

  return (
    <div className="layout-px-spacing">
      <div className="row layout-spacing pt-4">
        {!isLoading ?
          <>
            {Object?.keys(companyData)?.length > 0
              && Object?.keys(companyData?.data)?.length > 0
              && typeof companyData?.data?.company_info !== 'undefined'
              && companyData?.data?.company_info
              && Object?.keys(companyData?.data?.company_info)?.length > 0
              && <>
                <GeneralInformation
                  data={companyData?.data?.company_info}
                  cid={cid}
                  branches={companyData?.data?.branches} />
              </>
            }
            {Object?.keys(companyData)?.length > 0
              && Object?.keys(companyData?.data)?.length > 0
              && companyData?.data?.branches !== 'undefined'
              && <InsideCompanyProfile
                info={companyData?.data?.company_info}
                data={companyData?.data?.branches} />
            }
            {Object?.keys(companyData)?.length > 0
              && Object.keys(companyData?.data)?.length > 0
              && companyData?.data?.company_members !== 'undefined'
              && <TeamMembers data={companyData?.data?.company_members} />
            }
            {Object?.keys(exhibitionDetails)?.length > 0
              && exhibitionDetails?.data !== 'undefined'
              && <EventParticipate data={exhibitionDetails?.data} />
            }
            {Object?.keys(companyData)?.length > 0
              && Object?.keys(companyData?.data)?.length > 0
              && companyData?.data?.spotlight !== 'undefined'
              && <CompanySpotlight data={companyData?.data?.spotlight} />
            }
            {typeof cid !== 'undefined'
              && <SendQuote />
            }
          </> :
          <div className="col-xl-12 col-lg-12" style={{ margin: "15% 40%" }}>
            {/* <CirclesWithBar
              height="100"
              width="100"
              color="#bbce00"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              outerCircleColor=""
              innerCircleColor=""
              barColor=""
              ariaLabel='circles-with-bar-loading'
            /> */}
            <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="1.00"
              width="70"
              visible={true}
            />
          </div>
        }


      </div>
    </div>
  )
}

export default CompanyProfile