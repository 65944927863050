import { createSlice } from "@reduxjs/toolkit";
import {
  companyProfile,
  companyProfilePage,
  memberVisitProfile,
  companyList
} from "redux/Actions/Company";

const CompanySlice = createSlice({
  name: "companySlice",
  initialState: {
    isLoading: false,
    isVisit: false,
    companyProfileData: {},
    companyData: {},
    companyListData: {}
  },
  reducers: {
    setInitialState: (state) => {
      state.companyListData = {};
    }
  },
  extraReducers: (builder) => {
    /* Company Profile */
    builder.addCase(companyProfile.fulfilled, (state, action) => {
      // console.log('action.payload',action.payload);
      state.isLoading = false;
      state.companyProfileData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(companyProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(companyProfile.rejected, (state) => {
      state.isLoading = false;
    });
    
    /* Company Profile */
    builder.addCase(companyProfilePage.fulfilled, (state, action) => {
      // console.log('action.payload',action.payload);
      state.isLoading = false;
      state.companyData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(companyProfilePage.pending, (state) => {
      state.isLoading = true;
      state.companyData = {};
    });
    builder.addCase(companyProfilePage.rejected, (state) => {
      state.isLoading = false;
      state.companyData = {};
    });
   
    /* Memebr Visit Profile */
    builder.addCase(memberVisitProfile.fulfilled, (state, action) => {      
      state.isVisit = action.payload.status === 200 ? true: false;
    });

    /* Company Profile */
    builder.addCase(companyList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyListData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(companyList.pending, (state) => {
      state.isLoading = true;
      state.companyListData = {};
    });
    builder.addCase(companyList.rejected, (state) => {
      state.isLoading = false;
      state.companyListData = {};
    });

  }
});

export const { setInitialState } = CompanySlice.actions;
export default CompanySlice.reducer