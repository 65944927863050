const headingStyle = {
    margin: 0,
    color: '#fff',
    textAlign: 'center',
    background: 'rgb(0 0 0 / 70%)',
    position: 'absolute',
    bottom: "105px",
    marginLeft: "100px",
    zIndex: "10",
    padding: "6px 30px 10px 30px",
    borderRadius: "10px",
    fontSize: "20px"
};
const contentStyle = {
    margin: 0,
    color: '#fff',
    textAlign: 'center',
    position: 'absolute',
    bottom: "50px",
    marginLeft: "100px",
    zIndex: "10",
    padding: "6px 12px 10px 12px",
    borderRadius: "10px",
    fontSize: "15px"
};
const submitStyle = {
   
    // background: 'rgb(0 0 0 / 70%)',
};

export { headingStyle, contentStyle,submitStyle }