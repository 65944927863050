import React from 'react'
import "../../../assets/css/Hallofshame/HallofShame.css"

const HallofShame = () => {
    return (
        <div className="layout-px-spacing">
            <div className="row layout-spacing pt-4">
                <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                    <div className="widget-content widget-content-area br-6 " style={{marginTop:"50px"}}>
                        <h4 className="table-header" style={{fontSize: "20px"}}>Hall of Shame</h4>
                        <br /><br />
                        {/* <div className="table-responsive mb-4">
                            <table id="basic-dt" className="table table-hover" style={{width:"100%"}}>
                                <thead>
                                    <tr>
                                        <th>Departed Members</th>
                                        <th>Company Name</th>
                                        <th>Country</th>
                                        <th>Departed Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Tiger Nixon</td>
                                        <td>xyz company</td>
                                        <td>USA</td>
                                        <td>2011/04/25</td>
                                    </tr>
                                    <tr>
                                        <td>Garrett Winters</td>
                                        <td>Sample Company</td>
                                        <td>India</td>
                                        <td>2011/07/25</td>
                                    </tr>
                                    <tr>
                                        <td>Ashton Cox</td>
                                        <td>Demo Company</td>
                                        <td>Austraila</td>
                                        <td>2009/01/12</td>
                                    </tr>
                                    <tr>
                                        <td>Cedric Kelly</td>
                                        <td>One Company</td>
                                        <td>China</td>
                                        <td>2012/03/29</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Departed Members</th>
                                        <th>Country</th>
                                        <th>Company Name</th>
                                        <th>Departed Date</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div> */}
                        <h3 data-text="Coming soon..." className='coming'>Coming soon...</h3>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default HallofShame