import React, { useState, useEffect } from 'react'
import { memberAnnouncements } from "redux/Actions/Member";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Announce_Doc = () => {
  const dispatch = useDispatch();
  const { announcementsList } = useSelector(state => state.member);
  useEffect(() => {
    dispatch(memberAnnouncements({}));
  }, []);
  const [featuredData, setFeaturedData] = useState([]);
  useEffect(() => {
    let tempData = [];
    if (Object.keys(announcementsList).length > 0
      && announcementsList.data.length > 0) {
      announcementsList.data.map((item, index) => {
        tempData.push({
          title: item.title,
          description: item.description,
          img: item.thumbnail,
          index: index + 1,
          id: item.id
        });
      });      
    }
    setFeaturedData(tempData);
  }, [announcementsList])

  return (
    <div className="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing " style={{ marginTop: "25px" }}>
      <div className="widget widget-table-one">
        <div className="widget-heading">
          <h5 className="">Announcement</h5>
        </div>
        <div className="widget-content" style={{ padding: "15px" }}>
          <div className="">
            <Row xs={1} md={3} className="">
              {featuredData.length > 0 ?
              featuredData?.map((item, index) => {
                return (
                  <Col key={`we${index}`}>
                    <>
                      <div className="py-3">
                        <div className="card widget overflow-hidden p-0">
                          <div style={{ backgroundImage: `url(${item.img})`, backgroundSize: "cover", height: "200px" }}></div>
                          <div className="p-3" style={{ minHeight: "200px" }}>
                            <h6 className="mb-3 font-17 strong" style={{ fontWeight: "bolder" }}>{item?.title}</h6>
                            <p className="font-12 text-muted mb-3">
                              <span dangerouslySetInnerHTML={{ __html: item?.description.slice(0, 200) }} ></span></p>
                          </div>
                          <div className="col-12 card-footer">
                            <div className="d-flex col-6 justify-content-center btn btn-sm btn-primary text-white">
                              <Link to={`/announcements/details/${item.id}`}> Read More</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </Col>
                )
              }) : <div className="list-group-item list-group-item-action">No Data Found.</div>}
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Announce_Doc