import React, { useEffect, useState } from 'react';
import "assets/css/Spotlights/Spotlights.css";
// import spotlight from "assets/img/talaSpotlightBanner.jpeg"
import TalaSearch from '../TalaSearch/TalaSearch';
import SpotlightListing from "components/Spotlight/SpotlightListing";
import { useNavigate } from "react-router-dom";
// import { submitStyle } from '../../Exhibitions/ExhibitionCard/Carousal';
import { Carousel } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getSpotlightBanner } from "redux/Actions/Spotlight";

const TalaSpotlightsCard = () => {
  // getSpotlightBanner
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSpotlightBanner());
  }, [])
  const { spotlightBanner } = useSelector(state => state.spotlight);  
  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/spotlightsubmission")
  }
  return (
    <>
      <div className="col-lg-12 col-md-6 layout-spacing mt-4" >
        <div className='statbox widget box box-shadow'>
          <div className='custom-carousel'>
            <div className='container' style={{ maxWidth: "1200px" }}>
              <div className="row">
                <div className="col-lg-12 col-md-12 p-0">
                  <Carousel >
                    <div className='col-xl-12'>
                      <div className="overflow-hidden">
                        {Object.keys(spotlightBanner).length > 0
                          && Object.keys(spotlightBanner.data).length
                          && spotlightBanner.data.map((item, index) => {
                            return (<img key={`sp${index}`}
                              src={item?.thumbnail} style={{ width: "1200px" }} className='img-fluid' />)
                          })
                        }
                        <div className='mt-5 submitSpotlight'>
                          <button type="primary" shape="round" size={`lfarge`} onClick={handleClick} className='btnForsubmitSpotlight'>
                            Submit Spotlights
                          </button>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing mt-4'>
        <div className='widget widget-table-one'>
          <div className='widget-content' >
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
              <br /> <h4 style={{ textAlign: "center" }}>TALA Spotlight</h4><br />
            </div>
            <TalaSearch />
            <div className="row">
              <SpotlightListing />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TalaSpotlightsCard