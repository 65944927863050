import React, { useState, useEffect } from 'react'
import "assets/css/MyAccount.css"
import "assets/css/UploadFile.css"
import Modal from 'react-bootstrap/Modal';
import { Tabs } from 'antd';
import profileUpload from "assets/img/profileUpload.jpg"
import MySocialNetwork from './MySocialNetwork';
import ChangePassword from './ChangePassword';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setIsUpdate } from "redux/Reducers/MemberSlice";
import { updateProfile, memberProfile } from "redux/Actions/Member";
import { companyProfile } from "redux/Actions/Company";

const { TabPane } = Tabs;

const ImgUpload = ({
  onChange,
  src
}) =>
  <div className='' style={{ paddingLeft: "50px" }}>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap img-upload fas" >
        <img htmlFor="photo-upload" src={src} />
      </div>
      <input id="photo-upload" type="file" className='inputFields' onChange={onChange} />
    </label>
    <div className='col-12 uploadText' style={{ width: "110px", textAlign: "center" }}>Upload Profile</div>
  </div>

const CompanyLogoUpload = ({
  onChangeCompany,
  srcCompany
}) =>
  <div className='' style={{ paddingLeft: "50px" }}>
    <label htmlFor="photo-upload-company" className="custom-file-upload fas">
      <div className="img-wrap img-upload fas" >
        <img htmlFor="photo-upload" src={srcCompany} />
      </div>
      <input id="photo-upload-company" type="file" className='inputFields' onChange={onChangeCompany} />
    </label>
    <div className='col-12 uploadText' style={{ width: "110px", textAlign: "center" }}>Upload Company Profile</div>
  </div>

const MyAccount = ({ show, setShow }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(profileUpload);
  const { memberProfileData, isUpdate } = useSelector(state => state.member);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const [file, setFile] = useState(false);
  // console.log('memberProfileData',memberProfileData);
  const photoUpload = e => {
    // console.log('profile',e.target.files)
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file)
      setImagePreviewUrl(reader.result)
    }
    reader.readAsDataURL(file);
  }

  const [imageCompanyUrl, setImageCompanyUrl] = useState(profileUpload);
  const [companyFile, setCompanyFile] = useState(false);

  const { companyProfileData } = useSelector(state => state.company);
  // console.log('companyProfileData', companyProfileData);
  const photoCompanyUpload = e => {
    // console.log('comp',e.target.files)
    e.preventDefault();
    const reader1 = new FileReader();
    const file1 = e.target.files[0];
    reader1.onloadend = () => {
      setCompanyFile(file1)
      setImageCompanyUrl(reader1.result)
    }
    reader1.readAsDataURL(file1);
  }


  useEffect(() => {
    if (isUpdate) {
      setTimeout(() => {
        dispatch(companyProfile(memberProfileData?.data?.company_id));
        dispatch(memberProfile());
        dispatch(setIsUpdate(false));
      }, 3000);
    }
  }, [isUpdate])


  useEffect(() => {
    // console.log('yes')
    if (Object.keys(companyProfileData).length > 0
      && typeof companyProfileData.data !== 'undefined'
      && Object.keys(companyProfileData.data).length > 0) {
      setImageCompanyUrl(companyProfileData?.data?.company_info?.company_logo);
    }
  }, [companyProfileData])

  useEffect(() => {
    if (Object.keys(memberProfileData).length > 0
      && typeof memberProfileData.data !== 'undefined'
      && Object.keys(memberProfileData.data).length > 0) {
      setValue('first_name', `${memberProfileData.data?.first_name}`);
      setValue('last_name', `${memberProfileData.data?.last_name}`);
      setValue('phone', `${memberProfileData.data?.phone}`);
      setValue('email', `${memberProfileData.data?.email}`);
      setValue('designation', `${memberProfileData.data?.designation}`);
      setImagePreviewUrl(memberProfileData.data?.profile_logo_url);
    }
  }, [memberProfileData])

  const onSubmit = async (fdata) => {
    var formdata = new FormData();
    formdata.append("first_name", fdata.first_name);
    formdata.append("last_name", fdata.last_name);
    formdata.append("phone", fdata.phone);
    formdata.append("email", fdata.email);
    formdata.append("designation", fdata.designation);
    if (file) {
      formdata.append("profile_logo", file);
    }
    if (companyFile) {
      formdata.append("company_logo", companyFile);
    }

    dispatch(updateProfile(formdata));

  }

  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-80w" >
        <Modal.Header closeButton>
          <Modal.Title>My Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-row'>
            <div className='col-12'>
              <Tabs type="card">
                <TabPane key="static-1" tab="My General Information">
                  <div>
                    <div className='col-12 d-flex justify-content-center mt-3'>
                      <h4>GENERAL<span style={{ color: "#bbce00" }}> INFORMATION</span>
                      </h4>
                    </div>
                    <div className='col-12 d-flex justify-content-center mt-3'>
                      {isUpdate &&
                        <span className="success d-block">
                          Member infomation update Successfully...
                        </span>
                      }
                    </div>
                    <div className='col-12 d-flex flex-row'>
                      <div className='d-flex flex-column'>
                        <div className='col-3'>
                          {/* <UploadFile /> */}
                          <ImgUpload onChange={photoUpload} src={imagePreviewUrl} />
                        </div>
                        <div>
                          <CompanyLogoUpload onChangeCompany={photoCompanyUpload} srcCompany={imageCompanyUrl} />
                          {/* <UploadImg/> */}
                        </div>
                      </div>
                      <div className='col-9'>
                        <div className="row">
                          <form className="login-form mt-4"
                            onSubmit={handleSubmit(onSubmit)}
                            encType="multipart/form-data"
                            style={{ paddingRight: "30px", marginTop: "20px" }}>
                            <div className="col-md-12 clearfix">
                              <div className="single-query">
                                <label>First Name:</label>
                                <input type="text" placeholder="Bohdan"
                                  className="keyword-input"
                                  {...register("first_name", {
                                    required: "First name should not be empty",
                                  })} />
                                {errors.first_name && <span className="error d-block">{errors.first_name.message}</span>}
                              </div>
                            </div>
                            <div className="col-md-12 clearfix">
                              <div className="single-query">
                                <label>Last Name:</label>
                                <input type="text" placeholder="Kononets"
                                  className="keyword-input"
                                  {...register("last_name", {
                                    required: "Last name should not be empty",
                                  })} />
                                {errors.last_name && <span className="error d-block">{errors.last_name.message}</span>}
                              </div>
                            </div>
                            <div className="col-md-12 clearfix">
                              <div className="single-query">
                                <label>Phone:</label>
                                <input type="text" placeholder="(+01) 34 56 7890"
                                  className="keyword-input"
                                  {...register("phone", {
                                    required: "Phone should not be empty",
                                  })} />
                                {errors.phone && <span className="error d-block">{errors.phone.message}</span>}
                              </div>
                            </div>
                            <div className="col-md-12 clearfix">
                              <div className="single-query">
                                <label>Designation:</label>
                                <input type="text" placeholder="Enter Designation"
                                  className="keyword-input"
                                  {...register("designation", {
                                    required: "Designation should not be empty",
                                  })} />
                                {errors.designation &&
                                  <span className="error d-block">{errors.designation.message}</span>
                                }
                              </div>
                            </div>

                            <div className="col-md-12 clearfix">
                              <div className="single-query">
                                <label>Email Adress:</label>
                                <input type="text" placeholder="bohdan@ideahomes.com"
                                  className="keyword-input"
                                  {...register("email", {
                                    required: "Email should not be empty",
                                  })} />
                                {errors.email && <span className="error d-block">{errors.email.message}</span>}
                              </div>
                            </div>
                            <div className='col-12 d-flex justify-content-end mb-4 mt-3'>
                              <div className="col-4 btn btn-sm btn-primary text-white ">
                                <button type="submit" className="btn btn-primary mr-2">Submit</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={`My Social Network`} key={`social-network`}>
                  <MySocialNetwork />
                </TabPane>
                <TabPane tab={`Change Your Password`} key={`change-password`}>
                  <ChangePassword />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MyAccount