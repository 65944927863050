import React, { useEffect } from 'react'
import "assets/css/PastSubmission/PastSubmission.css";
import { getMonthlyReport } from "redux/Actions/MonthlyReport";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Table } from 'antd';
import { useState } from 'react';

const columns = [
  {
    title: 'S.no',
    dataIndex: 'key',
  },
  {
    title: 'Licensee Name',
    dataIndex: 'memberName',
  },
  {
    title: 'Report Number',
    dataIndex: 'reportNumber',
  },
  {
    title: 'Date',
    dataIndex: 'date',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

const PastSubmission = () => {

  const dispatch = useDispatch();
  const { monthlyReportList } = useSelector(state => state.monthlyReport);
  const [monthlyReports, setMonthlyReports] = useState([])
  useEffect(() => {
    dispatch(getMonthlyReport());    
  }, [])
  useEffect(() => {
    let monthlyReportArr = []
    if (Object?.keys(monthlyReportList)?.length > 0
      && monthlyReportList?.data?.length > 0
    ) {
      monthlyReportList?.data?.map((item, index) => {        
        monthlyReportArr.push({
          key: index + 1,
          memberName: item?.licence,
          reportNumber: item.report_number,
          date: moment(item.created_on).format('YYYY/MM/DD'),
          status: item.status === 0 ? 'Pending' : 'Approved'
        })
      })
      setMonthlyReports(monthlyReportArr)
    }   
  }, [monthlyReportList])

  return (
    <div className="widget-content widget-content-area br-6">
      <h4 className="annualTitles" style={{ fontSize: "20px" }}>Past Submission</h4><br /><br />
      <div className="table-responsive mb-4">
         <Table
            columns={columns}
            dataSource={monthlyReports}
            pagination={{
              pageSize: 5,
            }}
          />
      </div>
    </div>
  )
}

export default PastSubmission
