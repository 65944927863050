import React, { useEffect, useState } from 'react';
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai"
import "assets/css/AnnualConference/AnnualConference.css"
import Sponser from "./Sponser"
import Attend from "./Attend"
import { forwardRef } from "react";
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TbAlertCircle } from "react-icons/tb"

const JoinUs = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [basicActive, setBasicActive] = useState('tab1');
  const { conferenceBanner } = useSelector(state => state.annualconference);
  useEffect(() => {
    if (props?.sponser) {
      setBasicActive('tab2')
    }
  }, [props.sponser])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cancelHandle = (id) => {
    console.log('id', id);
    setShow(false)
    // dispatch(cancelAttendedExhibition({ event_id: id }))
    // toast.success("Already Submitted Events Cancelled Successfully")
  }
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const cancelHandle1 = (id) => {
    console.log('id', id);
    setShow1(false)
    // dispatch(cancelAttendedExhibition({ event_id: id }))
    // toast.success("Already Submitted Events Cancelled Successfully")
  }
  return (
    <>
      <div className="widget-header" >
        <div className="row">
          <div className="col-xl-12 col-md-12 col-sm-12 col-12">
            <h4 className='annualTitles'>Join the Event</h4>
            <br />
          </div>
        </div>
      </div>
      <div className='rounded-corner-pills-icon' ref={ref}>
        <MDBTabs pills className='mb-3 d-flex justify-content-center'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => setBasicActive('tab1')} active={basicActive === 'tab1'}>
              <AiOutlineHome className='pillsIcon' />
              Interest to Attend
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem className='px-3'>
            <MDBTabsLink onClick={() => setBasicActive('tab2')} active={basicActive === 'tab2'}>
              <AiOutlineUser className='pillsIcon' />
              Interest to Sponsor
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent>
          <MDBTabsPane show={basicActive === 'tab1'}>
            {conferenceBanner?.data?.already_interest_to_attended === 1 ?
              <div className='col-12 d-flex justify-content-center'>
                <div className="col-6 card-box mt-5">
                  <div style={{ color: "#0570BE", fontSize: "20px", textAlign: "center" }}><TbAlertCircle /></div>
                  <div className='al-sub text-center'>You are already submitted</div>

                  <div className='al-sub text-center'>
                    <span style={{ color: "blue" }} className="btn btn-sm bg-gradient-primary text-white mt-2"
                      onClick={handleShow}>
                      Cancel
                    </span>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{ color: "#1B4D70" }}><TbAlertCircle /> Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you Want to Cancel?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => cancelHandle()}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              :
              <Attend />
            }

          </MDBTabsPane>
          <MDBTabsPane show={basicActive === 'tab2'} >
            {conferenceBanner?.data?.already_sponser_to_attended === 1 ?
              <div className='col-12 d-flex justify-content-center'>
                <div className="col-6 card-box mt-5">
                  <div style={{ color: "#0570BE", fontSize: "20px", textAlign: "center" }}><TbAlertCircle /></div>
                  <div className='al-sub text-center'>You are already submitted</div>
                  <div className='al-sub text-center'>
                    <span style={{ color: "blue" }} className="btn btn-sm bg-gradient-primary text-white mt-2"
                      onClick={handleShow1}>
                      Cancel
                    </span>
                  </div>
                  <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header closeButton>
                      <Modal.Title style={{ color: "#1B4D70" }}><TbAlertCircle /> Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you Want to Cancel?</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose1}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={() => cancelHandle1()}>
                        Ok
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
              :
              <Sponser list={props} />
            }
          </MDBTabsPane>
        </MDBTabsContent>
      </div>
    </>
  )
});

export default JoinUs