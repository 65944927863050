import React, { useState, useEffect } from 'react'
import "assets/css/Dashboard/ContentLayout.css"
// import SearchBox from '../Dashboard/SearchBox/SearchBox'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaCalendarAlt } from "react-icons/fa"
import { MdOutlineModeEditOutline } from "react-icons/md"
import { TfiMenuAlt } from "react-icons/tfi"
import { GiCancel } from "react-icons/gi"
import { AiOutlineClockCircle, AiOutlineUser } from "react-icons/ai"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { HiOutlineArrowNarrowRight } from "react-icons/hi"
import { useForm } from "react-hook-form";
import {
  scheduleEvent,
  getScheduleEvent,
  updateScheduleEvent,
  cancelMetting
} from "redux/Actions/MeetMember";
import { RotatingLines } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialState } from "redux/Reducers/MeetMemberSlice";
import { setLogout } from "redux/Reducers/LoggedSlice";
import moment from 'moment'
import { useNavigate } from "react-router-dom";
import SearchBox from 'components/Search/SearchBox';
import MeetMemberSearchResult from './MeetMemberSearchResult/MeetMemberSearchResult';
import { ToastContainer, toast } from 'react-toastify';

const MeetMembers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setDescription] = useState('');
  const [isBlank, setIsBlank] = useState(false);
  const [clickValue, setClickValue] = useState(false);
  const [mId, memberId] = useState(0);
  const [updateEvent, setUpdateEvent] = useState(false);
  const [minTime, setMinTime] = useState(false);
  const [mValue, memberValue] = useState('');
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const {
    tokenExp,
    isLoading,
    eventList,
    isSuccess } = useSelector(state => state.meetmember);

  useEffect(() => {
    if (tokenExp && Object.keys(tokenExp).length > 0
      && tokenExp.error === 'Invalid token') {
      dispatch(setLogout());
      navigate("/");
    }
  }, [tokenExp])

  useEffect(() => {
    dispatch(getScheduleEvent());
  }, [])
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        reset();
        dispatch(getScheduleEvent());
        memberValue('');
        memberId(0);
        setClickValue(false);
        setUpdateEvent(false);
        setDescription(false);
        dispatch(setInitialState());
      }, 2000);
    }
  }, [isSuccess])
  const [events, setEvents] = useState([]);
  useEffect(() => {
    let event = [];
    if (Object.keys(eventList).length > 0
      && eventList.data.length > 0
    ) {
      eventList.data.map(item => {
        event.push({
          id: item.id,
          title: item.title,
          member_name: item?.schedule_with_member_name,
          schedule_with_member: item?.schedule_with_member,
          date: moment(item.from_date).format('YYYY-MM-DD'),
          fromtime: moment(item.from_date).format('HH:MM'),
          totime: moment(item.to_date).format('HH:MM'),
          start: moment(item.from_date).format('YYYY-MM-DDTHH:MM:ss'),
          end: moment(item.to_date).format('YYYY-MM-DDTHH:MM:ss'),
          extendedProps: {
            department: `${item.title}${item.id}`
          },
          description: item.description
        })
      })
    }
    setEvents(event)
  }, [eventList]);

  const eventClickUpdate = (info) => {
    let identy = info.event.extendedProps.department;
    let event = events.find((ev) => ev.extendedProps.department === identy);
    setValue('title', event.title);
    setValue('from_time', event.fromtime);
    setValue('to_time', event.totime);
    setValue('date', event.date);
    memberValue(event.member_name)
    memberId(event.schedule_with_member)
    setUpdateEvent(event);
    setClickValue(event.date);
    setDescription(event.description);
  }
  const handleOnClose = () => {
    setClickValue(false);
    setUpdateEvent(false);
    setDescription(false);
    memberValue('');
    memberId(0);
    reset();
  }
  const onSubmit = (data) => {
    data.schedule_with_member = mId;
    if (!value || value === '') {
      data.description = value;
      setIsBlank(true);
      return false;
    }
    else {
      data.description = value;
      setIsBlank(false);
    }
    if (data.eventid === '') {
      delete data.eventid;
      dispatch(scheduleEvent(data))
      toast.success("Meeting Schedule Successfully...")
    } else {
      dispatch(updateScheduleEvent(data))
    }
  }

  const selectHandle = (id, name) => {
    memberValue(name);
    memberId(id);
    setClickValue(true);
  }

  return (
    <div className="layout-px-spacing">
      <div className="row layout-spacing pt-4">
        <div className="col-xl-12 col-lg-4 col-md-4 col-sm-12">
          <div className='mt-4'>
            <SearchBox heading="Meet Licensee Search" type="meetmember"/>
            <br />
            <MeetMemberSearchResult selectHandle={selectHandle} />
            <br />
          </div>
        </div>
        <div className="widget-content widget-content-area">
          <div className="widget-header">
            <div className="row">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                displayEventEnd={true}
                events={events}
                eventContent={renderEventContent}
                // dateClick={(arg) => setClickValue(arg.dateStr)}
                validRange={{ start: new Date() }}
                eventClick={(info) => eventClickUpdate(info)}
                className="Calender"
              />
            </div>
          </div>
        </div>
        {clickValue &&
          /* Pop up code */
          <Modal show={true} onHide={() => handleOnClose()} dialogClassName="modal-90w">
            <Modal.Header closeButton>
              <div className='col-12 calenderIcon'>
                <FaCalendarAlt className='col-12 calenderIconMain d-flex justify-content-center align-items-center mt-1' />
              </div>
              <Modal.Title className='px-2'>New Meeting</Modal.Title>
              {updateEvent &&
                <Modal.Title className='px-2'
                  onClick={() => dispatch(cancelMetting(updateEvent?.id))}>
                  <Button className='cancel'> <GiCancel />
                    Cancel Meeting</Button>
                </Modal.Title>
              }
            </Modal.Header>
            <Modal.Body>
              {/* {isSuccess &&
                <span className="success d-block">
                  {isSuccess.msg}
                </span>
              } */}
              {isLoading &&
                <div className="spin-loader">
                  {/* <InfinitySpin width='200' color="#bbce00" /> */}
                  <RotatingLines
              strokeColor="#bbce00"
              strokeWidth="5"
              animationDuration="0.75"
              width="70"
              visible={true}
            />
                </div>
              }
              <Form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register("eventid", {
                  required: false,
                })} value={updateEvent?.id} />
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                  <div className="d-flex flex-row">
                    <AiOutlineUser style={{ fontSize: "17px" }} />
                    <Form.Label className='px-1'>Licensee</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Licensee"
                    autoFocus
                    className='p-3'
                    {...register("member_name", {
                      required: false,
                    })}
                    readOnly
                    value={mValue}
                  />

                </Form.Group>
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                  <div className="d-flex flex-row">
                    <MdOutlineModeEditOutline style={{ fontSize: "17px" }} />
                    <Form.Label className='px-1'>Meeting Subject</Form.Label>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    autoFocus
                    className='p-3'
                    {...register("title", {
                      required: "Meeting subject should not be empty",
                    })}
                  />
                  {errors.title && <span className="error d-block">{errors.title.message}</span>}
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Row>
                    <Col xs={6} md={1} className='d-flex flex-row mt-2'>
                      <AiOutlineClockCircle style={{ fontSize: "16px", marginTop: "3PX" }} />
                      <Form.Label className='px-1'>Date</Form.Label>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Control type='date' min={moment(new Date()).format('YYYY-MM-DD')}
                        {...register("date", {
                          required: "Date should not be empty",
                        })}
                      />
                      {errors.date && <span className="error d-block">{errors.date.message}</span>}
                    </Col>

                    <Col xs={6} md={3}>
                      <Form.Control type='time'
                        {...register("from_time", {
                          required: "From time should not be empty"
                        })}
                        onChange={(e) => setMinTime(e.target.value)} />
                      {errors.from_time && <span className="error d-block">{errors.from_time.message}</span>}
                    </Col>

                    <Col xs={6} md={1}>
                      <div><HiOutlineArrowNarrowRight style={{ fontSize: "16px", marginTop: "10PX" }} /></div>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Control type='time'
                        min={minTime ? minTime : ''}
                        {...register("to_time", {
                          required: "To time should not be empty",
                        })} />
                      {errors.to_time && <span className="error d-block">{errors.to_time.message}</span>}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <div className="d-flex flex-row">
                    <TfiMenuAlt style={{ fontSize: "16px", marginTop: "5PX" }} />
                    <Form.Label className='px-2'>Description</Form.Label>
                  </div>
                  <ReactQuill theme="snow" value={value} onChange={setDescription}
                    placeholder="Type details for this new meeting" />
                  {isBlank && <span className="error d-block">Description should not be empty</span>}
                </Form.Group>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => handleOnClose()}>
                    Close
                  </Button>
                  <Button type="submit" variant="primary" >
                    Save
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>

          </Modal>
          
        }
      </div>
      <ToastContainer />
    </div >
  )
}

export default MeetMembers

function renderEventContent(eventInfo) {
  // console.log('eventInfo', eventInfo.event.extendedProps);
  return (
    <div style={{ color: '#1b4d70',fontWeight:"900" }}>
      <b>{eventInfo.timeText}</b>
      <br/>
      <i>{eventInfo.event.title}</i>
    </div>
  )
}