import React, { useState, useEffect } from 'react'
import "assets/css/CompanyProfile/CompanyProfile.css"
import { GoClock } from "react-icons/go";
import moment from 'moment';
import { Link } from 'react-router-dom';

const CompanySpotlight = ({ data }) => {
  // console.log("data",data);
  const [spotlights, setspotlights] = useState([])
  const [indexValue, setIndexValue] = useState("")
  const [showAll, setShowAll] = useState(false)

  const spotlightData = data
  let curDate = moment(new Date());

  useEffect(() => {
    handleEvents();
    handleIndex()
  }, []);
  const handleEvents = () => {
    let eventArr = []
    spotlightData.map((item, index) => {
      const dateB = moment(item.date_add);
      const indexId = index + 1
      setIndexValue(indexId);
      let obj = {
        title: item.title,
        index: index + 1,
        message: item.message,
        date: curDate.diff(dateB, 'days'),
      }
      eventArr.push(obj)
    })
    setspotlights([...eventArr])
  }
  const handleIndex = () => {
    if (indexValue < 6) {
      setShowAll(true)
    } else {
      setShowAll(false)
    }
  }
  return (
    <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing"
      style={{ marginTop: "25px" }}>
      <div className="widget widget-chart-one">
        <div className="widget-heading">
          <div>
            <h5 className="">Spotlight</h5>
          </div>
        </div>
        <div className="widget-content mt-4 mb-4">
          {spotlights.length > 0 ?
            <>
              {spotlights.slice(0, 5).map((item, index) => {
                return (
                  <div key={`sp${index}`} className="mb-2 border-bottoms border-lights pb-3">
                    <span className="text-primary font-15">{item.title}</span>
                    <span className="float-right text-success-teal font-12">
                      <i className="las la-clock" >
                        <GoClock />
                      </i>
                      <span className="px-2" >{item.date} days ago </span>
                    </span>
                    <p className="font-12 mb-0 text-muted">
                      {item.message}
                    </p>
                  </div>
                )
              })}
              {showAll === true &&
                <Link to="/talaspotlight/viewmore">
                  <div className='col-12 d-flex justify-content-center mt-1'>
                    <span className="col-6 btn btn-block btn-primary">View All</span>
                  </div>
                </Link>
              }
            </>
            : <p>No Spotlight Found</p>
          }
        </div>
      </div>
      <div id='ctaForQuote' />
    </div>
  )
}

export default CompanySpotlight