import React from 'react'
import Videos from "../VideoGuide/Videos/Videos"
import TeamMembers from "../VideoGuide/TeamMembers/TeamMembers"

const VideoGuide = () => {
  return (
    // <div className="layout-px-spacing">
    //   <div className="row layout-spacing pt-4">
    //     <Videos />
    //     <TeamMembers />      
    //   </div>
    // </div>
    <div className="layout-px-spacing">
      <div className="row layout-spacing pt-4">
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content widget-content-area br-6 " style={{ marginTop: "50px" }}>
            <h4 className="table-header" style={{ fontSize: "20px" }}>Video Guide</h4>
            <br /><br />
            <h3 data-text="Coming soon..." className='coming'>Coming soon...</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoGuide