import React, { useState, useEffect } from "react";
import flight from "assets/img/flight3.png"
import talalogo from "assets/img/talaLogo.jpg"
import "assets/css/Login.css"
import ForgetModel from "pages/auth/ForgetPassword/ForgetModel/ForgetModel"
import { useForm } from "react-hook-form";
import { getLogin } from "redux/Actions/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedin, setExteraRoute } from "redux/Reducers/LoggedSlice";
import { setInitialState } from "redux/Reducers/AuthenticationSlice";
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import { memberProfile } from "redux/Actions/Member";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, isSuccess, error, userData } = useSelector(state => state.authentication);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isLoggedin } = useSelector(state => state.loggedin);

  const [ip, setIP] = useState('');
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    setIP(res.data.IPv4)
  }
  useEffect(() => {
    getData()
  }, [])


  const handleModel = () => {
    setIsModalOpen(false);
    dispatch(setInitialState());
  }
  useEffect(() => {
    dispatch(memberProfile());
    if (isSuccess || isLoggedin) {     
      setTimeout(() => {
        dispatch(setIsLoggedin(userData));
        
        dispatch(setExteraRoute('Dashboard'));
        navigate(`/dashboard`);
      }, 1500);
    }
  }, [isSuccess])

  const onSubmit = (data) => {
    data.ip = ip;
    dispatch(getLogin(data));
    toast.success("Login Successfully!!! Welcome to TALA...");
  }

  return (
    <div className='slider-area banner-style-5 bg-images'>
      <div className='wrapper d-flex'>
        <div className="container">
          <img src={talalogo} className="img-fluid" />
          <div className="row g-5">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="inner pt--100 pt_md--0 pt_sm--0">
                <div className="contents text-start">
                  <div className='card logincard'>
                    <div className="login-form-box checkout-page-style">
                      {/* {isLoading &&
                        <div className="spin-loader">                         
                          <RotatingLines
                            strokeColor="#bbce00"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={true}
                          />
                        </div>
                      } */}
                      <h3 className="mb-30">Login</h3>
                      {error && <span className="error d-block">
                        Email and password not match. Please try again </span>
                      }
                      <form className="login-form mt-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-box mb-4">
                          <input
                            type="text"
                            placeholder="Username or Email"
                            className='col-12'
                            {...register("email", {
                              onChange: (e) => { dispatch(setInitialState()); },
                              required: "Username or Email should not be empty",
                            })}
                          />
                          {errors.username && <span className="error d-block">{errors.username.message}</span>}
                        </div>
                        <div className="input-box mb-4">
                          <input
                            type="password"
                            placeholder="Password"
                            className="col-12"
                            name="password"
                            {...register("password", {
                              onChange: (e) => { dispatch(setInitialState()); },
                              required: "Password should not be empty",
                            })}
                          />
                          {errors.password && <span className="error d-block">{errors.password.message}</span>}
                        </div>
                        <div className="boxCheck">
                          <input id="one" type="checkbox" {...register("remember", {
                            required: false,
                          })} />
                          <span className="check"></span>
                          <label htmlFor="one" className='px-2'>Remember me</label>
                        </div>

                        <button type="submit" className="btn btn-primary btn-lg"> Login </button>

                        <div className="input-box forget col-12 d-flex justify-content-center">
                          <span className="lost-password" onClick={() => setIsModalOpen(true)} >Forget your Password?</span>
                        </div>
                        <ForgetModel
                          title="Forget Password ?"
                          subTitle="Enter Your email address below"
                          isModalOpen={isModalOpen}
                          handleModel={handleModel}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="banner-image col-6">
                <img src={flight} alt="Banner Images" className="img-fluid float-bob-y" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signin;
